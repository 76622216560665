import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://backend.details.net.sa/wp-json/wp/v2',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default axiosInstance;
