import {Link} from "react-router-dom";
import {useLocale} from "providers/LocaleProvider";
import {SCompFooter} from "./styles/SCompFooter";
import {SCompFooterContainer} from "./styles/SCompFooterContainer";
import {SCompFooterCopyrights} from "./styles/SCompFooterCopyrights";
import {SCompFooterLink} from "./styles/SCompFooterLink";
import {SCompFooterLinks} from "./styles/SCompFooterLinks";
import {SCompFooterLinksAndCopyrights} from "./styles/SCompFooterLinksAndCopyrights";
import {SCompFooterSocialBtn} from "./styles/SCompFooterSocialBtn";
import {SCompFooterWrapper} from "./styles/SCompFooterWrapper";

const Footer = () => {
  const {locale, isArabic} = useLocale();
  
  return (
      <SCompFooter className="footer">
        <SCompFooterContainer>
          <SCompFooterWrapper>
            {isArabic ? "تابعونا" : "Follow us"}
            <SCompFooterSocialBtn href='https://twitter.com/Details_net'>
              <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_122_813)">
                  <g clipPath="url(#clip1_122_813)">
                    <path d="M8.5092 20.6263C17.6617 20.6263 22.6662 12.9211 22.6662 6.23345C22.6662 6.01356 22.6582 5.79266 22.6492 5.58177C23.6239 4.8626 24.4638 3.97672 25.13 2.96506C24.226 3.37786 23.2633 3.64761 22.2764 3.76467C23.3178 3.12513 24.0935 2.13146 24.4613 0.966052C23.4859 1.55163 22.4178 1.9667 21.3029 2.19345C20.841 1.69008 20.2798 1.28804 19.6546 1.01276C19.0293 0.73749 18.3538 0.594977 17.6707 0.594238C16.3404 0.605066 15.0689 1.14349 14.1354 2.09123C13.2019 3.03896 12.6828 4.3185 12.6921 5.64874C12.6895 6.0359 12.7304 6.42215 12.8141 6.80017C10.8284 6.69502 8.88768 6.16971 7.12008 5.25895C5.35249 4.34819 3.79828 3.07271 2.56014 1.51678C1.92046 2.62916 1.72384 3.94187 2.00954 5.19285C2.29523 6.44383 3.04222 7.54104 4.10138 8.26544C3.31025 8.24055 2.53719 8.02266 1.8495 7.63076V7.69972C1.84416 8.85873 2.23755 9.98432 2.96367 10.8877C3.68979 11.7911 4.70447 12.4173 5.83752 12.6613C5.10659 12.8653 4.33789 12.8944 3.59363 12.7462C3.90358 13.7446 4.51881 14.6207 5.35273 15.2511C6.18665 15.8815 7.19726 16.2345 8.24233 16.2605C6.48764 17.6634 4.30799 18.4279 2.06139 18.4284C1.66532 18.4271 1.2696 18.4044 0.875977 18.3604C3.14475 19.8416 5.79575 20.63 8.5052 20.6293" fill="#707272"/>
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_122_813">
                    <rect width="24.25" height="20.4" fill="white" transform="translate(0.879883 0.409668)"/>
                  </clipPath>
                  <clipPath id="clip1_122_813">
                    <rect width="24.25" height="20.0331" fill="white" transform="translate(0.879883 0.593262)"/>
                  </clipPath>
                </defs>
              </svg>
            </SCompFooterSocialBtn>
            {/*<SCompFooterSocialBtn>*/}
            {/*  <svg width="28" height="21" viewBox="0 0 28 21" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*    <g clipPath="url(#clip0_122_817)">*/}
            {/*      <g clipPath="url(#clip1_122_817)">*/}
            {/*        <path d="M27.2637 3.48407C27.1095 2.89099 26.8023 2.34879 26.3728 1.91172C25.9432 1.47466 25.4065 1.15805 24.8162 0.993576C22.6566 0.408694 14.0004 0.408691 14.0004 0.408691C14.0004 0.408691 5.34412 0.408694 3.18456 0.993576C2.59425 1.15805 2.05749 1.47466 1.62797 1.91172C1.19845 2.34879 0.891231 2.89099 0.737057 3.48407C-0.0308419 7.96851 -0.0308419 12.5509 0.737057 17.0353C0.890882 17.6279 1.1975 18.1698 1.62628 18.6068C2.05506 19.0438 2.59099 19.3607 3.18056 19.5258C5.34012 20.1107 13.9964 20.1107 13.9964 20.1107C13.9964 20.1107 22.6526 20.1107 24.8122 19.5258C25.4025 19.3613 25.9392 19.0447 26.3688 18.6077C26.7983 18.1706 27.1055 17.6284 27.2597 17.0353C28.0276 12.5509 28.0276 7.96852 27.2597 3.48407H27.2637ZM11.2309 14.4818V6.03355L18.4215 10.2597L11.2309 14.4818Z" fill="#707272"/>*/}
            {/*      </g>*/}
            {/*    </g>*/}
            {/*    <defs>*/}
            {/*      <clipPath id="clip0_122_817">*/}
            {/*        <rect width="27.68" height="19.7" fill="white" transform="translate(0.160156 0.409668)"/>*/}
            {/*      </clipPath>*/}
            {/*      <clipPath id="clip1_122_817">*/}
            {/*        <rect width="27.6784" height="19.7" fill="white" transform="translate(0.161133 0.409668)"/>*/}
            {/*      </clipPath>*/}
            {/*    </defs>*/}
            {/*  </svg>*/}
            
            {/*</SCompFooterSocialBtn>*/}
            {/*<SCompFooterSocialBtn>*/}
            {/*  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*    <g clipPath="url(#clip0_122_821)">*/}
            {/*      <g clipPath="url(#clip1_122_821)">*/}
            {/*        <path d="M19.6558 4.44479V4.33486C19.6668 3.81939 19.5736 3.307 19.3818 2.82841C19.19 2.34982 18.9036 1.91488 18.5397 1.54965C18.1757 1.18442 17.7418 0.896421 17.2639 0.70291C16.786 0.509399 16.274 0.414359 15.7585 0.423488C11.9064 0.414827 8.05165 0.414827 4.19424 0.423488C3.6788 0.416162 3.16724 0.513553 2.69055 0.709756C2.21386 0.90596 1.78197 1.19689 1.42103 1.56493C1.0601 1.93297 0.777632 2.37045 0.590752 2.85087C0.403872 3.33129 0.316463 3.84465 0.333829 4.35984C0.333829 8.24523 0.384797 12.1226 0.316842 16.008C0.300285 16.5438 0.392041 17.0774 0.586619 17.5769C0.781198 18.0764 1.07459 18.5315 1.44923 18.9149C1.82387 19.2983 2.27203 19.6022 2.76689 19.8082C3.26174 20.0143 3.79311 20.1184 4.32914 20.1143C8.12659 20.0973 11.931 20.1222 15.7325 20.1063C16.2572 20.114 16.778 20.0147 17.2632 19.8146C17.7483 19.6145 18.1876 19.3177 18.5543 18.9423C18.921 18.5669 19.2075 18.1208 19.3962 17.6311C19.5849 17.1414 19.6718 16.6184 19.6518 16.094C19.6598 14.1473 19.6518 12.1916 19.6518 10.2439L19.6558 4.44479ZM13.2052 17.7359C12.1299 17.7608 11.0546 17.7439 9.97935 17.7439C8.90408 17.7439 7.82879 17.7688 6.75351 17.7359C5.67084 17.6763 4.6495 17.2143 3.88983 16.4406C3.13017 15.6669 2.68701 14.6372 2.64728 13.5537C2.6053 11.3441 2.6053 9.14263 2.64728 6.93311C2.69594 5.84911 3.1456 4.82185 3.90899 4.0507C4.67238 3.27956 5.69505 2.81954 6.7785 2.75992C8.92906 2.71795 11.0876 2.71795 13.2381 2.75992C14.3326 2.80918 15.3676 3.27172 16.1343 4.05421C16.901 4.8367 17.3424 5.8809 17.3694 6.97609C17.4034 9.14363 17.4113 11.3192 17.3694 13.4857C17.3419 14.5896 16.8969 15.642 16.1242 16.4307C15.3514 17.2195 14.3083 17.6859 13.2052 17.7359Z" fill="#707272"/>*/}
            {/*        <path d="M13.1122 3.75805C11.0549 3.71541 8.99227 3.71541 6.92433 3.75805C6.0382 3.77609 5.19442 4.14069 4.574 4.77363C3.95357 5.40658 3.6059 6.25746 3.60556 7.14378C3.57158 9.21738 3.57158 11.3 3.60556 13.3746C3.60817 13.8224 3.69925 14.2653 3.87362 14.6778C4.04798 15.0902 4.30216 15.4642 4.62154 15.7781C4.94092 16.092 5.31921 16.3396 5.73464 16.5068C6.15007 16.674 6.59446 16.7575 7.04225 16.7523H9.9463C10.9536 16.7523 11.9529 16.7603 12.9603 16.7523C13.4169 16.752 13.8688 16.6606 14.2897 16.4834C14.7105 16.3062 15.0917 16.0469 15.4111 15.7205C15.7304 15.3941 15.9813 15.0073 16.1493 14.5826C16.3172 14.158 16.3987 13.7042 16.389 13.2477V7.23771C16.4031 6.79388 16.3294 6.35162 16.1723 5.93631C16.0151 5.521 15.7775 5.1408 15.473 4.81752C15.1686 4.49424 14.8034 4.23424 14.3982 4.05243C13.9931 3.87063 13.5561 3.77059 13.1122 3.75805ZM9.98826 14.0272C9.23561 14.0392 8.49645 13.8267 7.86521 13.4166C7.23397 13.0065 6.73932 12.4175 6.44447 11.7249C6.14963 11.0323 6.06797 10.2675 6.20994 9.52828C6.3519 8.78904 6.71103 8.1089 7.24145 7.57478C7.77187 7.04066 8.44949 6.6768 9.18773 6.52971C9.92597 6.38261 10.6913 6.45895 11.3859 6.74897C12.0806 7.039 12.673 7.52953 13.0874 8.1579C13.5019 8.78628 13.7196 9.52395 13.7128 10.2767C13.7163 10.768 13.6226 11.2552 13.437 11.7101C13.2514 12.165 12.9776 12.5788 12.6314 12.9274C12.2852 13.276 11.8734 13.5527 11.4198 13.7414C10.9661 13.9302 10.4796 14.0273 9.98826 14.0272ZM14.1784 7.11879C13.924 7.10766 13.6839 6.99818 13.5087 6.81343C13.3334 6.62868 13.2368 6.3831 13.2391 6.12846C13.2356 5.99747 13.2592 5.86717 13.3086 5.74576C13.3579 5.62436 13.4318 5.51449 13.5257 5.42306C13.6196 5.33163 13.7314 5.26062 13.854 5.21451C13.9767 5.16839 14.1076 5.14817 14.2384 5.15511C14.493 5.16604 14.7338 5.27382 14.9116 5.45642C15.0894 5.63902 15.1907 5.88264 15.1948 6.13745C15.1882 6.40173 15.0779 6.65282 14.8878 6.83645C14.6976 7.02008 14.4428 7.1215 14.1784 7.11879Z" fill="#707272"/>*/}
            {/*        <path d="M10.0225 7.44867C9.46735 7.43108 8.91951 7.5791 8.44877 7.87389C7.97803 8.16868 7.60568 8.5969 7.37912 9.10402C7.15255 9.61114 7.08204 10.1742 7.17655 10.7215C7.27107 11.2689 7.52634 11.7757 7.90986 12.1775C8.29337 12.5792 8.78779 12.8578 9.33014 12.9776C9.87248 13.0974 10.4382 13.0532 10.9553 12.8504C11.4724 12.6476 11.9175 12.2956 12.2338 11.839C12.5501 11.3825 12.7235 10.8421 12.7317 10.2868C12.7411 9.92228 12.6784 9.55952 12.5472 9.21935C12.416 8.87917 12.2188 8.56828 11.9671 8.30454C11.7153 8.04081 11.4139 7.82942 11.0802 7.68254C10.7465 7.53566 10.387 7.45619 10.0225 7.44867Z" fill="#707272"/>*/}
            {/*      </g>*/}
            {/*    </g>*/}
            {/*    <defs>*/}
            {/*      <clipPath id="clip0_122_821">*/}
            {/*        <rect width="19.34" height="19.71" fill="white" transform="translate(0.319824 0.409668)"/>*/}
            {/*      </clipPath>*/}
            {/*      <clipPath id="clip1_122_821">*/}
            {/*        <rect width="19.34" height="19.6968" fill="white" transform="translate(0.319824 0.416504)"/>*/}
            {/*      </clipPath>*/}
            {/*    </defs>*/}
            {/*  </svg>*/}
            
            {/*</SCompFooterSocialBtn>*/}
            <SCompFooterSocialBtn href='https://www.linkedin.com/company/detailsdigital/'>
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_122_829)">
                  <g clipPath="url(#clip1_122_829)">
                    <path d="M4.48522 7.13379H0.344238V20.6594H4.48522V7.13379Z" fill="#707272"/>
                    <path d="M2.41885 5.28765C1.93476 5.29558 1.45925 5.15929 1.05287 4.89612C0.646486 4.63294 0.327599 4.25479 0.13682 3.80981C-0.0539593 3.36482 -0.108005 2.87313 -0.0184456 2.39733C0.0711142 1.92153 0.300238 1.48314 0.639748 1.13797C0.979257 0.792807 1.41379 0.556477 1.88805 0.459065C2.36231 0.361654 2.85483 0.407569 3.30291 0.590968C3.75098 0.774367 4.13434 1.08695 4.4042 1.48893C4.67405 1.89091 4.81819 2.36409 4.81825 2.84825C4.82155 3.16615 4.76203 3.48158 4.64311 3.77642C4.52419 4.07126 4.34821 4.33971 4.12528 4.56636C3.90234 4.79302 3.63682 4.97341 3.34399 5.09719C3.05115 5.22096 2.73676 5.28569 2.41885 5.28765Z" fill="#707272"/>
                    <path d="M19.9677 20.6584H15.8347V14.0781C15.8347 12.5115 15.8097 10.496 13.6832 10.496C11.5317 10.496 11.2018 12.2065 11.2018 13.9681V20.6584H7.06885V7.13378H11.0329V8.98033H11.0918C11.482 8.29267 12.0533 7.72526 12.7436 7.3399C13.434 6.95454 14.2168 6.76602 15.0069 6.79487C19.1908 6.79487 19.9697 9.59418 19.9697 13.2403L19.9677 20.6584Z" fill="#707272"/>
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_122_829">
                    <rect width="19.95" height="20.25" fill="white" transform="translate(0.0200195 0.409668)"/>
                  </clipPath>
                  <clipPath id="clip1_122_829">
                    <rect width="19.9451" height="20.25" fill="white" transform="translate(0.0224609 0.409668)"/>
                  </clipPath>
                </defs>
              </svg>
            </SCompFooterSocialBtn>
          </SCompFooterWrapper>
          <SCompFooterLinksAndCopyrights>
            <SCompFooterCopyrights>
              {isArabic ?
                  "جميع الحقوق محفوظة لشركة تفاصيل الأفكار لتقنية المعلومات © " + new Date().getFullYear() + "."
                  : "All copyrights reserved to Details Digital Company © " + new Date().getFullYear()}
            </SCompFooterCopyrights>
            <SCompFooterLinks>
              <Link to={`/${locale}/privacy-policy`}>
                <SCompFooterLink>
                  {isArabic ? "سياسة الاستخدام والخصوصية" : "Privacy Policy"}
                </SCompFooterLink>
              </Link>
              <Link to={`/${locale}/careers`}>
                <SCompFooterLink>
                  {isArabic ? "الوظائف" : "Careers"}
                </SCompFooterLink>
              </Link>
            </SCompFooterLinks>
          </SCompFooterLinksAndCopyrights>
        </SCompFooterContainer>
      </SCompFooter>
  )
}


export default Footer;