import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useLocale} from "providers/LocaleProvider";
import CompNewsDetails from "components/CompNewsDetails";
import CompRelatedNews from "components/CompRelatedNews";

const NewsDetails = () => {
  const {setLocale} = useLocale();
  const {locale} = useParams();
  
  useEffect(() => {
    setLocale(locale)
  }, [locale, setLocale]);
  
  return (
      <>
        <CompNewsDetails/>
        <CompRelatedNews/>
      </>
  );
};

export default NewsDetails;
