import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useLocale} from "providers/LocaleProvider";
import CompNews from "components/CompNews";
import CompPageHero from "components/CompPageHero";

const News = () => {
  const {setLocale, isArabic} = useLocale();
  const {locale} = useParams();
  
  useEffect(() => {
    setLocale(locale)
  }, [locale, setLocale]);
  
  return (
      <>
        <CompPageHero pageTitle={isArabic ? "الأخبار" : "News"}/>
        <CompNews/>
      </>
  );
};

export default News;
