import {useEffect, useState} from "react";
import {fetchData} from "helpers/functions/fetchData";
import {useLoader} from "providers/LoaderProvider";
import {useLocale} from "providers/LocaleProvider";
import {Link} from "react-router-dom";
import LearnMoreBtn from "components/LearnMoreBtn";
import {formatDate} from "helpers/functions/formatDate";
import CompNewsSidebar from "components/CompNewsSidebar";
import {SCompNews} from "./styles/SCompNews";
import {SCompNewsCard} from "./styles/SCompNewsCard";
import {SCompNewsCardDate} from "./styles/SCompNewsCardDate";
import {SCompNewsCards} from "./styles/SCompNewsCards";
import {SCompNewsCardText} from "./styles/SCompNewsCardText";
import {SCompNewsCardTitle} from "./styles/SCompNewsCardTitle";
import {SCompNewsCardImage} from "./styles/SCompNewsCardImage";
import {SCompNewsContainer} from "./styles/SCompNewsContainer";
import {SCompNewsWrapper} from "./styles/SCompNewsWrapper";

const CompNews = () => {
  const [data, setData] = useState([]);
  const {openLoader, closeLoader} = useLoader();
  const {locale, isArabic} = useLocale();
  
  const fetchDataAsync = async () => {
    openLoader();
    try {
      const response = await fetchData('/posts', locale);
      setData(response);
      closeLoader();
    } catch {
      closeLoader();
    }
  }
  
  useEffect(() => {
    fetchDataAsync();
  }, [locale]);
  
  
  return (
      <SCompNews className="company-news">
        <SCompNewsContainer>
          <SCompNewsWrapper>
            <SCompNewsCards isArabic={isArabic}>
              {data?.map(card => (
                  <SCompNewsCard key={card.id}>
                    <SCompNewsCardImage>
                      {card?._embedded?.['wp:featuredmedia']?.[0]?.source_url && (
                          <img src={card._embedded['wp:featuredmedia'][0].source_url} alt=""/>
                      )}
                    </SCompNewsCardImage>
                    <SCompNewsCardDate>
                      {isArabic ? "بتاريخ " : "Date "}
                      {formatDate(card.date)}
                    </SCompNewsCardDate>
                    <Link to={`/${locale}/news/${card.id}`}>
                      <SCompNewsCardTitle>
                        {card.title.rendered}
                      </SCompNewsCardTitle>
                    </Link>
                    <SCompNewsCardText dangerouslySetInnerHTML={{__html: card.excerpt.rendered}}/>
                    <Link to={`/${locale}/news/${card.id}`}>
                      <LearnMoreBtn/>
                    </Link>
                  </SCompNewsCard>
              ))}
            </SCompNewsCards>
            <CompNewsSidebar news={data.slice(0, 3)} locale={locale} isArabic={isArabic}/>
          </SCompNewsWrapper>
        </SCompNewsContainer>
      </SCompNews>
  );
};

export default CompNews;
