import React, { Fragment, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const Card = ({ data, setIsActive, isActive, isArabic }) => {
  const cardRef = useRef(null);

  const handleClick = () => {
    setIsActive(data.id);
  };
  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0, left: 0 });
  }, []);
  useEffect(() => {
    cardRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  }, [isActive, cardRef.current]);

  console.log(data?._links?.["wp:attachment"]?.[0]?.href?.source_url);
  return (
    <Fragment>
      {isActive !== data?.id ? (
        <div className="project-card">
          <div className="center-image">
            {data?._embedded?.["wp:featuredmedia"]?.[0]?.source_url && (
              <img src={data?._embedded?.["wp:featuredmedia"]?.[0]?.source_url} alt="Project Logo" />
            )}
          </div>

          <div className="display-project" onClick={handleClick}>
            <img src="/project-arrow.svg" alt="arrow icon" />
            <h5 style={{ bottom: isArabic ? "3px" : "0" }}> {isArabic ? "عرض المشروع" : "Show Project"}</h5>
          </div>
        </div>
      ) : (
        <div className={`active-card ${isActive == data.id && "active"}`} ref={cardRef}>
          {data?._links["wp:attachment"]?.[0]?.href && (
            <img
              src={data?._embedded?.["wp:featuredmedia"]?.[0]?.source_url}
              alt="company logo"
              className="company-logo"
              style={{ left: isArabic && "44px", right: !isArabic && "44px" }}
            />
          )}
          <div className="right-text">
            {data?.title?.rendered && (
              <div>
                <h3>{isArabic ? "أسم المشروع" : "Project Name"}</h3>
                <p>{data?.title?.rendered}</p>
              </div>
            )}
            {data?.content?.rendered && (
              <div>
                <h3>{isArabic ? "تفاصيل المشروع" : "Project Description"}</h3>
                <p dangerouslySetInnerHTML={{ __html: data?.content?.rendered }}></p>
              </div>
            )}
            {data?.acf?.scope && (
              <div>
                <h3>{isArabic ? "النوع" : "Type"}</h3>
                <p>{data?.acf?.scope}</p>
              </div>
            )}
          </div>
          {data?.acf?.technologies.length && (
            <div>
              <h3>{isArabic ? "الأدوات المستخدمة" : "Tools"}</h3>
              <div className="frameworks">
                {data?.acf?.technologies?.map((img, id) => (
                  <img key={id} src={img.tech} alt="Tool picture" className="frmaework-img" />
                ))}
              </div>
            </div>
          )}
          {data?.acf?.project_link && (
            <Link className="link" to={data?.acf?.project_link} target="_blank">
              {isArabic ? "معاينة الموقع" : "Try Website"}
              <p>{isArabic ? "←" : "→"}</p>
            </Link>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default Card;
