import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  color: rgba(217, 217, 217, 0.90);
  font-size: 13px;
  font-weight: 700;
  line-height: 173.077%;
  margin-top: 16px;
`;

const Medium = css``;

const Large = css``;

const XLarge = css`
  margin-top: 0;
`;

const XXLarge = css``;

export const SCompFooterCopyrights = styled.p`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
