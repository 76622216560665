import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  overflow: hidden;
  padding: 35px 24px 40px;
`;

const Medium = css``;

const Large = css`
  padding: ${({isArabic}) => isArabic ? "35px 143px 25px 0" : "35px 0 25px 143px"};
`;

const XLarge = css``;

const XXLarge = css``;

export const SCompTextImageLeftContainer = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
