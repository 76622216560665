import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useLocale} from "providers/LocaleProvider";
import CompJobDetails from "components/CompJobDetails";

const JobDetails = () => {
  const {setLocale} = useLocale();
  const {locale} = useParams();
  
  useEffect(() => {
    setLocale(locale)
  }, [locale, setLocale]);
  
  return (
      <CompJobDetails/>
  );
};

export default JobDetails;
