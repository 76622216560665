import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocale } from "providers/LocaleProvider";
import CompRelatedProducts from "components/CompRelatedProducts";
import CompProductDetails from "components/CompProductDetails";
import Contact from "components/CompContact/Contact";

const ProductDetails = () => {
  const { setLocale } = useLocale();
  const { locale } = useParams();

  useEffect(() => {
    setLocale(locale);
  }, [locale, setLocale]);

  return (
    <>
      <CompProductDetails />
      <Contact />
      <CompRelatedProducts />
    </>
  );
};

export default ProductDetails;
