import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  padding: 80px 24px;
`;

const Medium = css``;

const Large = css`
  padding: 40px 135px 140px;
`;

const XLarge = css``;

const XXLarge = css``;

export const SCompWhyUsContainer = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
