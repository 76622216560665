import {useEffect, useRef, useState} from "react";
import {useLocale} from "providers/LocaleProvider";
import {useParams} from "react-router-dom";
import API from "api";
import Button from "components/Button";
import SuccessModalMessage from "components/SuccessModalMessage";
import {SJobApplicationForm} from "./styles/SJobApplicationForm";
import {SJobApplicationFormContent} from "./styles/SJobApplicationFormContent";
import {SJobApplicationFormFileInput} from "./styles/SJobApplicationFormFileInput";
import {SJobApplicationFormFileInputText} from "./styles/SJobApplicationFormFileInputText";
import {SJobApplicationFormHeader} from "./styles/SJobApplicationFormHeader";
import {SJobApplicationFormHeaderBtn} from "./styles/SJobApplicationFormHeaderBtn";
import {SJobApplicationFormWrapper} from "./styles/SJobApplicationFormWrapper";
import {SSJobApplicationFormHeaderTitle} from "./styles/SSJobApplicationFormHeaderTitle";

const initialFormData = {
  email: '',
  mobile: '',
  cv: null,
  fullname: '',
}

const JobApplicationForm = ({isOpen, setIsOpen}) => {
  const ref = useRef(null);
  const {isArabic} = useLocale();
  const {id} = useParams();
  const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const {name, value, files} = e.target;

    if (name === 'cv' && files.length > 0) {
      const selectedFile = files[0];
      const allowedFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

      if (allowedFileTypes.includes(selectedFile.type)) {
        setFormData({
          ...formData,
          [name]: selectedFile,
        });
        setErrors({...errors, cv: ''});
      } else {
        setErrors({...errors, cv: 'Please upload a valid PDF, DOC, or DOCX file'});
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!formData.email) {
      newErrors.email = isArabic ? "هذا الحقل مطلوب" : 'Email is required';
    }
    if (!formData.mobile) {
      newErrors.mobile = isArabic ? "هذا الحقل مطلوب" : 'Mobile is required';
    }
    if (!formData.cv) {
      newErrors.cv = isArabic ? "هذا الحقل مطلوب" : 'CV is required';
    }
    if (!formData.fullname) {
      newErrors.fullname = isArabic ? "هذا الحقل مطلوب" : 'Full Name is required';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('email', formData.email);
    formDataToSend.append('mobile', formData.mobile);
    formDataToSend.append('cv', formData.cv);
    formDataToSend.append('fullname', formData.fullname);
    formDataToSend.append('postid', id);

    try {
      setIsSubmitting(true);
      const response = await API.post('/career/apply', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      if (response?.data?.status) {
        setIsOpen(false)
        setIsSuccessModalOpened(true);
        setFormData(initialFormData);
      }
      setIsSubmitting(false);
    } catch {
      setIsOpen(false);
      setIsSubmitting(false);
    }
  };


  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (e.target === ref.current) {
        setIsOpen(false);
      }
    })
  }, []);

  useEffect(() => {
    document.documentElement.style.overflow = isOpen ? 'hidden' : 'auto';

    return () => {
      document.documentElement.style.overflow = 'auto';
    }

  }, [isOpen]);

  return (
      <>
        <SJobApplicationForm ref={ref} isOpen={isOpen}>
          <SJobApplicationFormContent>
            <SJobApplicationFormHeader>
              <SSJobApplicationFormHeaderTitle>
                {isArabic ? "التقدم للوظيفة" : "Apply for the job"}
              </SSJobApplicationFormHeaderTitle>
              <SJobApplicationFormHeaderBtn
                  onClick={() => {
                    setIsOpen(false);
                  }}
              >
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_264_420)">
                    <path d="M17.4994 2.91675C25.5534 2.91675 32.0827 9.44593 32.0827 17.5001C32.0827 25.5542 25.5534 32.0834 17.4994 32.0834C9.4452 32.0834 2.91602 25.5542 2.91602 17.5001C2.91602 9.44593 9.4452 2.91675 17.4994 2.91675ZM14.4059 12.344C13.8363 11.7745 12.913 11.7745 12.3434 12.344C11.8177 12.8698 11.7773 13.697 12.2221 14.2691L12.3434 14.4064L15.437 17.4999L12.3433 20.5937C11.7738 21.1631 11.7738 22.0865 12.3433 22.656C12.869 23.1817 13.6962 23.2221 14.2683 22.7773L14.4057 22.656L17.4994 19.5623L20.5931 22.656C21.1625 23.2255 22.086 23.2255 22.6554 22.656C23.1811 22.1304 23.2216 21.3031 22.7768 20.731L22.6554 20.5937L19.5617 17.4999L22.6553 14.4064C23.2248 13.8369 23.2248 12.9135 22.6553 12.344C22.1296 11.8183 21.3024 11.7779 20.7303 12.2227L20.5929 12.344L17.4994 15.4376L14.4059 12.344Z" fill="#D4D4D4"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_264_420">
                      <rect width="35" height="35" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </SJobApplicationFormHeaderBtn>
            </SJobApplicationFormHeader>
            <SJobApplicationFormWrapper onSubmit={handleSubmit}>
              <div>
                <input
                    type="text"
                    name="fullname"
                    value={formData.fullname}
                    onChange={handleChange}
                    placeholder={isArabic ? "الأسم" : "Name"}
                />
                {errors.fullname && <p className="error">{errors.fullname}</p>}
              </div>
              <div>
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder={isArabic ? "البريد الإلكتروني" : "Email"}
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
              <div>
                <input
                    type="text"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    placeholder={isArabic ? "رقم التليفون" : "Mobile"}
                />
                {errors.mobile && <p className="error">{errors.mobile}</p>}
              </div>
              <SJobApplicationFormFileInput>
                <input
                    type="file"
                    accept=".pdf,.doc,.docx"
                    name="cv"
                    onChange={handleChange}
                />
                <SJobApplicationFormFileInputText>
                  <p>
                    {formData?.cv?.name ? formData.cv.name : isArabic ? "السيرة الذاتية" : "CV"}
                  </p>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_264_177)">
                      <path d="M5.63646 10.5857C4.26962 9.21888 4.26962 7.0028 5.63646 5.63597C7.00329 4.26913 9.21937 4.26913 10.5862 5.63597L18.3644 13.4141C19.7312 14.781 19.7312 16.9971 18.3644 18.3639C16.9976 19.7307 14.7815 19.7307 13.4146 18.3639L9.34877 14.298C8.86061 13.8099 8.86061 13.0184 9.34877 12.5303C9.83692 12.0421 10.6284 12.0421 11.1165 12.5303L14.8289 16.2426C15.2194 16.6331 15.8525 16.6331 16.2431 16.2426C16.6336 15.852 16.6336 15.2189 16.2431 14.8284L12.5308 11.116C11.2616 9.84684 9.20376 9.84684 7.93455 11.116C6.66535 12.3852 6.66535 14.443 7.93455 15.7122L12.0004 19.7781C14.1483 21.926 17.6307 21.926 19.7786 19.7781C21.9265 17.6302 21.9265 14.1478 19.7786 11.9999L12.0004 4.22175C9.85253 2.07387 6.37013 2.07387 4.22224 4.22175C2.07436 6.36964 2.07436 9.85204 4.22224 11.9999L4.57576 12.3534C4.96628 12.744 5.59948 12.744 5.99001 12.3535C6.38053 11.963 6.38053 11.3298 5.99001 10.9393L5.63646 10.5857Z" fill="#606060"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_264_177">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </SJobApplicationFormFileInputText>
                {errors.cv && <p className="error">{errors.cv}</p>}
              </SJobApplicationFormFileInput>
              <Button as="button" type='submit' disabled={isSubmitting}>
                {isArabic ? "التقدم للوظيفة" : "Apply for the job"}
              </Button>
            </SJobApplicationFormWrapper>
          </SJobApplicationFormContent>
        </SJobApplicationForm>
        <SuccessModalMessage
            isOpen={isSuccessModalOpened}
            setIsOpen={setIsSuccessModalOpened}
            title={isArabic ? "تم التقديم علي الوظيفة بنجاح !" : "You have successfully applied for the job!"}
            text={isArabic ? "تهانينا! نشكرك على رغبتك ان تكون جزءاً منا. سنقوم بمراجعة استمارتك قريباً." : "Congratulations! Thank you for wanting to be part of us. We will review your application soon."}
            icon={<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M57.6329 13.8L57.3996 26.4333C57.3663 28.1666 58.4663 30.4666 59.8663 31.5L68.1329 37.7666C73.4329 41.7666 72.5663 46.6666 66.2329 48.6666L55.4663 52.0333C53.6663 52.6 51.7663 54.5667 51.2996 56.4L48.7329 66.2C46.6996 73.9333 41.6329 74.7 37.4329 67.9L31.5663 58.4C30.4996 56.6666 27.9663 55.3666 25.9663 55.4666L14.8329 56.0333C6.86627 56.4333 4.5996 51.8333 9.7996 45.7666L16.3996 38.1C17.6329 36.6667 18.1996 34 17.6329 32.2L14.2329 21.4C12.2663 15.0667 15.7996 11.5667 22.0996 13.6333L31.9329 16.8666C33.5996 17.4 36.0996 17.0333 37.4996 16L47.7663 8.59998C53.3329 4.63332 57.7663 6.96665 57.6329 13.8Z" fill="#FD5D63"/>
              <path d="M71.4658 68.2334L61.3658 58.1334C60.3991 57.1668 58.7991 57.1668 57.8324 58.1334C56.8658 59.1001 56.8658 60.7001 57.8324 61.6668L67.9324 71.7668C68.4324 72.2668 69.0658 72.5001 69.6991 72.5001C70.3324 72.5001 70.9658 72.2668 71.4658 71.7668C72.4324 70.8001 72.4324 69.2001 71.4658 68.2334Z" fill="#FD5D63"/>
            </svg>
            }
        />
      </>
  )
}

export default JobApplicationForm;
