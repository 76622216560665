import {createContext, useContext, useEffect, useState} from "react";

const LocaleContext = createContext();

export function LocaleProvider({children}) {
  const [locale, setLocale] = useState("ar");
  
  useEffect(() => {
    document.documentElement.lang = locale
  }, [locale]);
  
  return (
      <LocaleContext.Provider value={{locale, setLocale}}>
        {children}
      </LocaleContext.Provider>
  );
}

export function useLocale() {
  const {locale, setLocale} = useContext(LocaleContext);
  const isArabic = locale === "ar";
  
  return {locale, setLocale, isArabic};
}
