import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  position: relative;
  height: 0;
  padding-top: 40.49%;
  overflow: hidden;
  
  img {
    position: absolute;
    border-radius: 7px;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SNewsCardIcon = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
