import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";


const Small = css`
  display: inline-flex;
  align-items: center;
  color: #FD5D63;
  font-size: 19px;
  font-weight: 300;
  line-height: 157.895%;
  margin-top: auto;
  
  svg {
    ${({isArabic}) => isArabic ? "margin-right: 5px" : "margin-left: 5px; transform: rotate(180deg)"};
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SLearnMoreBtn = styled.span`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
