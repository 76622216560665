import {useLocale} from "providers/LocaleProvider";
import {SSectionTitle} from "./styles/SSectionTitle";


const SectionTitle = ({children, isCenter, withoutLine}) => {
  const {isArabic} = useLocale();
  
  return (
      <SSectionTitle isArabic={isArabic} isCenter={isCenter} withoutLine={withoutLine}>
        {children}
      </SSectionTitle>
  );
};

export default SectionTitle;
