import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  background: #F5F5F5;
  margin-top: 30px;
  overflow: hidden;
  padding: 45px 24px ;
`;

const Medium = css``;

const Large = css`
  padding: 45px 50px 45px 70px;
`;

const XLarge = css``;

const XXLarge = css``;

export const SCompContactUsForm = styled.form`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
