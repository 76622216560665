import {SLoader} from "./styles/SLoader";
import {SLoaderSpinner} from "./styles/SLoaderSpinner";

const Loader = () => {
  return (
      <SLoader>
        <SLoaderSpinner/>
      </SLoader>
  )
}

export default Loader;