import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0 8.14815px 6.51852px 0 rgba(0, 0, 0, 0.01), 0 50px 100px 0 rgba(0, 0, 0, 0.04);
  padding: 30px;
  display: flex;
  flex-direction: column;
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SCompCareersCard = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
