import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  color: #6B7280;
  font-size: 20px;
  font-weight: 300;
  line-height: 150%;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const Medium = css``;

const Large = css`
  max-width: 90%;
`;

const XLarge = css``;

const XXLarge = css``;

export const SCompNewsCardText = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
