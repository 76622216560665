import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  background: #F5F5F5;
  padding: 16px 35px 24px;
  margin-bottom: 24px;
  min-width: 286px;
`;

const Medium = css`
  margin-bottom: 0;
  ${({isArabic}) => isArabic ? "margin-left: 24px" : "margin-right: 24px"};
  
  &:last-of-type {
    margin: 0;
  }
`;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SCompContactUsCard = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
