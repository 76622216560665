import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { LoaderProvider } from "providers/LoaderProvider";
import { LocaleProvider } from "providers/LocaleProvider";
import { store } from "./store/index";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <Provider store={store}>
      <BrowserRouter>
        <LocaleProvider>
          <LoaderProvider>
            <App />
          </LoaderProvider>
        </LocaleProvider>
      </BrowserRouter>
    </Provider>
  </React.Fragment>,
);
