import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useLocale } from "providers/LocaleProvider";
import { SHeader } from "./styles/SHeader";
import { SHeaderBurgerMenu } from "./styles/SHeaderBurgerMenu";
import { SHeaderContainer } from "./styles/SHeaderContainer";
import { SHeaderLanguage } from "./styles/SHeaderLanguage";
import { SHeaderLink } from "./styles/SHeaderLink";
import { SHeaderLinks } from "./styles/SHeaderLinks";
import { SHeaderLinksAndBtns } from "./styles/SHeaderLinksAndBtns";
import { SHeaderLogo } from "./styles/SHeaderLogo";
import { SHeaderWrapper } from "./styles/SHeaderWrapper";
import logo from "assets/images/logo.png";

const Header = () => {
  const location = useLocation();
  const { isArabic, locale } = useLocale();
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  useEffect(() => {
    document.body.style.overflow = isMenuOpened ? "hidden" : "unset";
  }, [isMenuOpened]);

  return (
    <SHeader className="header">
      <SHeaderContainer>
        <SHeaderWrapper>
          <Link to={`/${locale}`}>
            <SHeaderLogo
              isArabic={isArabic}
              onClick={() => {
                setIsMenuOpened(false);
              }}
            >
              <img src={logo} alt="Details Digital" />
            </SHeaderLogo>
          </Link>
          <SHeaderBurgerMenu
            onClick={() => {
              setIsMenuOpened((prev) => !prev);
            }}
          >
            {isMenuOpened ? (
              <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_264_420)">
                  <path
                    d="M17.4994 2.91675C25.5534 2.91675 32.0827 9.44593 32.0827 17.5001C32.0827 25.5542 25.5534 32.0834 17.4994 32.0834C9.4452 32.0834 2.91602 25.5542 2.91602 17.5001C2.91602 9.44593 9.4452 2.91675 17.4994 2.91675ZM14.4059 12.344C13.8363 11.7745 12.913 11.7745 12.3434 12.344C11.8177 12.8698 11.7773 13.697 12.2221 14.2691L12.3434 14.4064L15.437 17.4999L12.3433 20.5937C11.7738 21.1631 11.7738 22.0865 12.3433 22.656C12.869 23.1817 13.6962 23.2221 14.2683 22.7773L14.4057 22.656L17.4994 19.5623L20.5931 22.656C21.1625 23.2255 22.086 23.2255 22.6554 22.656C23.1811 22.1304 23.2216 21.3031 22.7768 20.731L22.6554 20.5937L19.5617 17.4999L22.6553 14.4064C23.2248 13.8369 23.2248 12.9135 22.6553 12.344C22.1296 11.8183 21.3024 11.7779 20.7303 12.2227L20.5929 12.344L17.4994 15.4376L14.4059 12.344Z"
                    fill="#D4D4D4"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_264_420">
                    <rect width="35" height="35" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 24 24" fill="none">
                <path d="M20 7L4 7" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M20 12L4 12" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M20 17L4 17" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
              </svg>
            )}
          </SHeaderBurgerMenu>
          <SHeaderLinksAndBtns isMenuOpened={isMenuOpened}>
            <SHeaderLinks>
              <NavLink to={`/${locale}/about-us`} className={({ isActive }) => (isActive ? "active" : "")}>
                <SHeaderLink
                  onClick={() => {
                    setIsMenuOpened(false);
                  }}
                >
                  {isArabic ? "من نحن" : "About us"}
                </SHeaderLink>
              </NavLink>
              <NavLink to={`/${locale}/products`} className={({ isActive }) => (isActive ? "active" : "")}>
                <SHeaderLink
                  onClick={() => {
                    setIsMenuOpened(false);
                  }}
                >
                  {isArabic ? "المنتجات" : "Products"}
                </SHeaderLink>
              </NavLink>
              <NavLink to={`/${locale}/projects`} className={({ isActive }) => (isActive ? "active" : "")}>
                <SHeaderLink
                  onClick={() => {
                    setIsMenuOpened(false);
                  }}
                >
                  {isArabic ? "المشاريع" : "Projects"}
                </SHeaderLink>
              </NavLink>
              <Link to={`/${locale}#services`}>
                <SHeaderLink
                  onClick={() => {
                    setIsMenuOpened(false);
                  }}
                >
                  {isArabic ? "الخدمات" : "Services"}
                </SHeaderLink>
              </Link>
              <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={`/${locale}/news`}>
                <SHeaderLink
                  onClick={() => {
                    setIsMenuOpened(false);
                  }}
                >
                  {isArabic ? "الأخبار" : "News"}
                </SHeaderLink>
              </NavLink>
              <NavLink to={`/${locale}/careers`} className={({ isActive }) => (isActive ? "active" : "")}>
                <SHeaderLink
                  onClick={() => {
                    setIsMenuOpened(false);
                  }}
                >
                  {isArabic ? "انضم إلينا" : "Join us"}
                </SHeaderLink>
              </NavLink>
              <NavLink to={`/${locale}/contact-us`} className={({ isActive }) => (isActive ? "active" : "")}>
                <SHeaderLink
                  onClick={() => {
                    setIsMenuOpened(false);
                  }}
                >
                  {isArabic ? "تواصل معنا" : "Contact us"}
                </SHeaderLink>
              </NavLink>
            </SHeaderLinks>
            <NavLink to={isArabic ? location.pathname.replace("/ar", "/en") : location.pathname.replace("/en", "/ar")}>
              <SHeaderLanguage
                isArabic={isArabic}
                onClick={() => {
                  setIsMenuOpened(false);
                }}
              >
                {isArabic ? "English" : "اللغة العربية"}
              </SHeaderLanguage>
            </NavLink>
          </SHeaderLinksAndBtns>
        </SHeaderWrapper>
      </SHeaderContainer>
    </SHeader>
  );
};

export default Header;
