import {useLocale} from "providers/LocaleProvider";
import {SLearnMoreBtn} from "./styles/SLearnMoreBtn";

const LearnMoreBtn = () => {
  const {isArabic} = useLocale();
  
  return (
      <SLearnMoreBtn isArabic={isArabic}>
        {isArabic ? "اعرف المزيد" : "Learn more"}
        <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9644 4.1543H2.34326L3.54443 5.30664V6.75195L0.429199 3.50977V3.4707L3.54443 0.228516V1.67383L2.32373 2.8457H13.9644V4.1543Z" fill="currentColor"/>
        </svg>
      </SLearnMoreBtn>
  );
};

export default LearnMoreBtn;
