import { useEffect, useState } from "react";
import { fetchData } from "helpers/functions/fetchData";
import { useLoader } from "providers/LoaderProvider";
import SectionTitle from "components/SectionTitle";
import { useLocale } from "providers/LocaleProvider";
import { SCompOurPartners } from "./styles/SCompOurPartners";
import { SCompOurPartnersContainer } from "./styles/SCompOurPartnersContainer";
import "./styles/partners.css";
import AOS from "aos";
import "aos/dist/aos.css";

const CompOurPartners = () => {
  const [data, setData] = useState([]);
  const { openLoader, closeLoader } = useLoader();
  const { isArabic } = useLocale();

  const fetchDataAsync = async () => {
    openLoader();
    try {
      const response = await fetchData("/clients", 'ar', `&per_page=100`);
      setData(response);
      closeLoader();
    } catch {
      closeLoader();
    }
  };

  useEffect(() => {
    fetchDataAsync();
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <SCompOurPartners>
      <SCompOurPartnersContainer>
        <SectionTitle withoutLine isCenter>
          {isArabic ? "شركاء النجاح" : "Our Valued Customers"}
        </SectionTitle>
        <div style={{ position: "relative", width: "100%" }}>
          <div className="our-partners">
            {data?.map(
              (logo) =>
                logo?._embedded?.["wp:featuredmedia"]?.[0]?.source_url && (
                  <div data-aos="fade-up">
                    <img src={logo._embedded["wp:featuredmedia"][0].source_url} alt="" />
                  </div>
                ),
            )}
          </div>
        </div>
      </SCompOurPartnersContainer>
    </SCompOurPartners>
  );
};

export default CompOurPartners;
