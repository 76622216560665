import CompPageHero from "components/CompPageHero";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useLocale} from "providers/LocaleProvider";
import CompProducts from "../../components/CompProducts";

const Products = () => {
  const {setLocale, isArabic} = useLocale();
  const {locale} = useParams();
  useEffect(() => {
    setLocale(locale)
  }, [locale, setLocale]);
  
  return (
      <>
        <CompPageHero pageTitle={isArabic ? "المنتجات" : "Products"}/>
        <CompProducts/>
      </>
  );
};

export default Products;
