import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #FFF;
  padding: 32px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 752px;
  min-height: 393px;
  animation-name: animateTop;
  animation-duration: 0.5s;
  @keyframes animateTop {
    from {
      top: -300px;
      opacity: 0
    }
    to {
      top: 50%;
      opacity: 1
    }
  }
  
  svg {
    width: 80px;
    height: 80px;
  }
`;

const Medium = css`
  width: 80%;
  padding: 45px;
`;

const Large = css`
  width: 52.91%;
  padding: 45px 134px;
`;

const XLarge = css`

`;

const XXLarge = css``;

export const SSuccessModalMessageContent = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
