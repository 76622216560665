import { css } from "styled-components";

export const breakpoints = {
  xxLarge: 1920,
  xLarge: 1440,
  large: 1280,
  medium: 768,
};

export const MIN_MEDIUM = (...args) => css`
  @media (min-width: ${breakpoints.medium / 16}em) {
    ${css(() => args)};
  }
`;

export const MIN_LARGE = (...args) => css`
  @media (min-width: ${breakpoints.large / 16}em) {
    ${css(() => args)};
  }
`;
export const MIN_XLARGE = (...args) => css`
  @media (min-width: ${breakpoints.xLarge / 16}em) {
    ${css(() => args)};
  }
`;
export const MIN_XXLARGE = (...args) => css`
  @media (min-width: ${breakpoints.xxLarge / 16}em) {
    ${css(() => args)};
  }
`;