import React from 'react'
import {Link} from 'react-router-dom'

function PageNotFound() {
  return (
      <div className='container' style={{textAlign: 'center', padding: "60px"}}>
        <div>
          
          <h1 style={{color: 'darkred', marginTop: '3em'}}>
            404 Page Not Found
          </h1>
        </div>
        <div>
          
          <Link to="/" className='btn btn-danger m-3'>Go To Home Page</Link>
        </div>
      </div>
  )
}

export default PageNotFound