import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";


const CSSSecondaryButton = css`
  border: 1px solid #C5C5C5;
  color: #B7B7B7;
  font-weight: 300;
  background-color: #FFF;
`;


const Small = css`
  display: flex;
  min-width: 179px;
  height: 48px;
  padding: 14.66px 35.84px 17px 35px;
  justify-content: center;
  align-items: center;
  border: 1px solid #FD5D63;
  background: #FD5D63;
  color: #FFF;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  line-height: 100%;
  outline: 0;
  border-image-width: 0;
  text-decoration: none;
  ${({btnType}) => btnType === "secondary" && CSSSecondaryButton};
  
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SButton = styled.a`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
