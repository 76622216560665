import {createGlobalStyle} from "styled-components";
import IBMPlexSansArabicRegular from 'constants/fonts/IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Regular.ttf';
import IBMPlexSansArabicLight from 'constants/fonts/IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Light.ttf';
import IBMPlexSansArabicBold from 'constants/fonts/IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Bold.ttf';

export const GStyleBase = createGlobalStyle`
  
  @font-face {
    font-family: 'IBM Plex Sans Arabic';
    src: url(${IBMPlexSansArabicLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'IBM Plex Sans Arabic';
    src: url(${IBMPlexSansArabicRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'IBM Plex Sans Arabic';
    src: url(${IBMPlexSansArabicBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  html {
    direction: ${({isArabic}) => isArabic ? "rtl" : "ltr"};
  }
  
  :root {
    margin: 0;
    font-family: ${({isArabic}) => isArabic ? 'IBM Plex Sans Arabic' : 'IBM Plex Sans Arabic'}, sans-serif;
    
    font-size: 16px;
  }
  
  a {
    text-decoration: none;
    display: block;
  }
`;
