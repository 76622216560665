import SectionTitle from "components/SectionTitle";
import { useLocale } from "providers/LocaleProvider";
import { SCompStatistics } from "./styles/SCompStatistics";
import { SCompStatisticsCard } from "./styles/SCompStatisticsCard";
import { SCompStatisticsCardText } from "./styles/SCompStatisticsCardText";
import { SCompStatisticsCardTitle } from "./styles/SCompStatisticsCardTitle";
import { SCompStatisticsContainer } from "./styles/SCompStatisticsContainer";
import { SCompStatisticsText } from "./styles/SCompStatisticsText";
import { SCompStatisticsWrapper } from "./styles/SCompStatisticsWrapper";
import { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";

const CompStatistics = () => {
  const { isArabic } = useLocale();
  const [counterOn, setCounterOn] = useState(false);

  return (
    <SCompStatistics className="statistics">
      <SCompStatisticsContainer>
        <SectionTitle isCenter>{isArabic ? "الإحصائيات" : "Stats"}</SectionTitle>
        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
          <SCompStatisticsWrapper>
            <SCompStatisticsCard>
              <SCompStatisticsCardTitle>
                +{counterOn && <CountUp start={0} end={6} duration={2} delay={0} />} {` `}
                {isArabic ? "سنوات" : "Years"}
              </SCompStatisticsCardTitle>
              <SCompStatisticsCardText>{isArabic ? "الإنشاء" : "Established"}</SCompStatisticsCardText>
            </SCompStatisticsCard>
            <SCompStatisticsCard>
              <SCompStatisticsCardTitle>
                +{counterOn && <CountUp start={0} end={4} duration={2} delay={0} />}
              </SCompStatisticsCardTitle>
              <SCompStatisticsCardText>{isArabic ? "حلول مبتكرة" : "Innovative Solutions"}</SCompStatisticsCardText>
            </SCompStatisticsCard>
            <SCompStatisticsCard>
              <SCompStatisticsCardTitle>
                +{counterOn && <CountUp start={0} end={50} duration={1} delay={0} />}
              </SCompStatisticsCardTitle>
              <SCompStatisticsCardText>{isArabic ? "العملاء" : "Clients"}</SCompStatisticsCardText>
            </SCompStatisticsCard>
            <SCompStatisticsCard>
              <SCompStatisticsCardTitle>
                +{counterOn && <CountUp start={0} end={75} duration={1} delay={0} />}
              </SCompStatisticsCardTitle>
              <SCompStatisticsCardText>{isArabic ? "مشروع" : "Project"}</SCompStatisticsCardText>
            </SCompStatisticsCard>
          </SCompStatisticsWrapper>
        </ScrollTrigger>
      </SCompStatisticsContainer>
    </SCompStatistics>
  );
};

export default CompStatistics;
