import CompPageHero from "components/CompPageHero";
import { useLocale } from "providers/LocaleProvider";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./styles/index.css";
import Card from "./Card";
import { fetchData } from "helpers/functions/fetchData";
import { useLoader } from "providers/LoaderProvider";

const Projects = () => {
  let [isActive, setIsActive] = useState(1);
  const { setLocale, isArabic } = useLocale();
  const { locale } = useParams();
  const { openLoader, closeLoader } = useLoader();
  let [Data, setData] = useState([]);
  useEffect(() => {
    setLocale(locale);
  }, [locale, setLocale]);
  const fetchDataAsync = async () => {
    openLoader();
    try {
      const response = await fetchData("/projects", locale, `&per_page=100&acf_format=standard`);
      setData(response);
      setIsActive(response?.[0]?.id);
      closeLoader();
    } catch {
      closeLoader();
    }
  };

  useEffect(() => {
    fetchDataAsync();
  }, [locale]);

  return (
    <Fragment>
      <CompPageHero pageTitle={isArabic ? "مشاريعنا" : "Our Projects"} />
      <div className="projects-wrapper">
        {Data.map((pr) => {
          return <Card data={pr} isActive={isActive} setIsActive={setIsActive} isArabic={isArabic} />;
        })}
      </div>
    </Fragment>
  );
};

export default Projects;
