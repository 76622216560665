import { useEffect, useState } from "react";
import { fetchData } from "helpers/functions/fetchData";
import { useLoader } from "providers/LoaderProvider";
import SectionTitle from "components/SectionTitle";
import { useLocale } from "providers/LocaleProvider";
import ProductCard from "components/ProductCard";
import { SCompCompanyProducts } from "./styles/SCompCompanyProducts";
import { SCompCompanyProductsContainer } from "./styles/SCompCompanyProductsContainer";
import { SCompCompanyProductsWrapper } from "./styles/SCompCompanyProductsWrapper";
import AOS from "aos";
import "aos/dist/aos.css";

const CompCompanyProducts = () => {
  const [data, setData] = useState([]);
  const { openLoader, closeLoader } = useLoader();
  const { isArabic, locale } = useLocale();

  const fetchDataAsync = async () => {
    openLoader();
    try {
      const response = await fetchData("/products", locale);
      setData(response);
      closeLoader();
    } catch {
      closeLoader();
    }
  };

  useEffect(() => {
    fetchDataAsync();
  }, [locale]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <SCompCompanyProducts className="company-products">
      <SCompCompanyProductsContainer>
        <SectionTitle isCenter>{isArabic ? "منتجاتنا" : "Our Products"}</SectionTitle>
        <SCompCompanyProductsWrapper>
          {data?.map((card) => (
            <ProductCard key={card.id} data={card} isWhite data-aos="fade-up" />
          ))}
        </SCompCompanyProductsWrapper>
      </SCompCompanyProductsContainer>
    </SCompCompanyProducts>
  );
};

export default CompCompanyProducts;
