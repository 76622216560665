import Hero from "components/Hero";
import CompTextImageRight from "components/CompTextImageRight";
import CompCompanyProducts from "components/CompCompanyProducts";
import CompCompanyServices from "components/CompCompanyServices";
import CompCompanyNews from "components/CompCompanyNews";
import CompNewsletter from "components/CompNewsletter";
import CompOurPartners from "components/CompOurPartners";
import CompStatistics from "components/CompStatistics";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useLocale} from "providers/LocaleProvider";

const Home = () => {
  const {setLocale} = useLocale();
  const {locale} = useParams();
  
  useEffect(() => {
    setLocale(locale)
  }, [locale, setLocale]);
  
  return (
      <>
        <Hero/>
        <CompTextImageRight/>
        <CompCompanyServices/>
        <CompCompanyProducts/>
        <CompStatistics/>
        <CompCompanyNews/>
        <CompOurPartners/>
        <CompNewsletter/>
      </>
  );
};

export default Home;
