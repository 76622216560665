import {useEffect, useState} from "react";
import {fetchData} from "helpers/functions/fetchData";
import {useLoader} from "providers/LoaderProvider";
import {useLocale} from "providers/LocaleProvider";
import {useParams} from "react-router-dom";
import {SPrivacyPolicy} from "./styles/SPrivacyPolicy";
import {SPrivacyPolicyContainer} from "./styles/SPrivacyPolicyContainer";
import {SPrivacyPolicyTabContent} from "./styles/SPrivacyPolicyTabContent";

const PrivacyPolicy = () => {
  const [data, setData] = useState();
  const {openLoader, closeLoader} = useLoader();
  const {setLocale} = useLocale();
  const {locale} = useParams();
  
  useEffect(() => {
    setLocale(locale);
  }, [locale, setLocale]);
  
  const fetchDataAsync = async () => {
    openLoader();
    try {
      const response = await fetchData('/pages/3', locale);
      setData(response);
      closeLoader();
    } catch {
      closeLoader();
    }
  }
  
  useEffect(() => {
    fetchDataAsync();
  }, [locale]);
  
  
  return (
      <SPrivacyPolicy>
        <SPrivacyPolicyContainer>
          <SPrivacyPolicyTabContent>
            <div dangerouslySetInnerHTML={{__html: data?.content?.rendered}}/>
          </SPrivacyPolicyTabContent>
        </SPrivacyPolicyContainer>
      </SPrivacyPolicy>
  );
};

export default PrivacyPolicy;
