import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  width: 90px;
  line-height: 0;
  flex-shrink: 0;
  display: block;
  
  img {
    width: 100%;
    height: auto;
    max-height: 61px;
    object-fit: contain;
  }
`;

const Medium = css``;

const Large = css`
  width: 152px;
  ${({isArabic}) => isArabic ? "margin-left: 60px" : "margin-right: 60px"};
`;

const XLarge = css``;

const XXLarge = css``;

export const SHeaderLogo = styled.span`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
