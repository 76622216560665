import React, { createContext, useContext, useState } from 'react';

const LoaderContext = createContext();

export function LoaderProvider({ children }) {
    const [isLoading, setIsLoading] = useState(false);

    const openLoader = () => {
        setIsLoading(true);
    };

    const closeLoader = () => {
        setIsLoading(false);
    };

    return (
        <LoaderContext.Provider value={{ isLoading, openLoader, closeLoader }}>
            {children}
        </LoaderContext.Provider>
        );
}

export function useLoader() {
    return useContext(LoaderContext);
}
