import {SCompText} from "./styles/SCompText";
import {SCompTextContainer} from "./styles/SCompTextContainer";


const CompText = ({children}) => {
  
  return (
      <SCompText>
        <SCompTextContainer>
          <p>{children}</p>
        </SCompTextContainer>
      </SCompText>
  );
};

export default CompText;
