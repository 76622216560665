import { useLocale } from "providers/LocaleProvider";
import { useState, useEffect } from "react";
import "./contact.css";
import axios from "axios";
import { useSelector } from "react-redux";

const Contact = () => {
  const { isArabic } = useLocale();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const newName = useSelector((state) => state.product.name);
  useEffect(() => {
    setName(newName);
  }, [newName]);
  const handelSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://backend.details.net.sa/wp-json/wp/v2/contactus/send", {
        email,
        mobile,
        message,
        subject: name,
      })
      .then((res) => {
        alert(res.data.message);
      })
      .catch((err) => console.log(err));
  };
  return (
    <section className="form-par">
      <h3>{isArabic ? "تواصل معنا" : "Contact us"}</h3>
      <section className="contact">
        <section className="form-detail">
          <p>
            {isArabic
              ? "يسرنا تزويدك بالمعلومات والدعم الذي تحتاجه. املأ هذا النموذج لطلب تفاصيل المنتج، الأسعار ، نسخة عرض ، أو أي معلومات أخرى تحتاجها."
              : "We're committed to providing you with the information and support you require. Fill out this form to request product details, demo, pricing, or any other information you need."}
          </p>
          <section className={` social`}>
            <div>
              <h4>{isArabic ? "أتصل بنا" : "Call us"}</h4>
              <div className="phone">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g clip-path="url(#clip0_856_256)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.8289 15.8287C11.9831 19.6745 8.42028 20.0956 7.37458 20.1341C6.11007 20.1806 4.82027 19.1485 4.26287 18.0908C5.15248 17.0467 6.31128 16.2369 7.58098 15.3589C8.32928 16.1072 9.25208 17.4975 10.4819 17.0001C11.1802 16.7177 12.9064 15.9228 14.4147 14.4145C15.9231 12.9061 16.7179 11.1799 17.0003 10.4817C17.4982 9.25085 16.1047 8.32636 15.3559 7.5775C16.2334 6.28681 17.0299 5.09842 18.0718 4.25344C19.1432 4.81393 20.1814 6.09394 20.1343 7.37436C20.0958 8.42002 19.6747 11.9829 15.8289 15.8287ZM7.44818 22.1327C8.88908 22.0797 12.9698 21.5163 17.2431 17.2429C21.5165 12.9695 22.0799 8.88886 22.1329 7.44791C22.2137 5.25245 20.5319 3.11926 18.5883 2.28629C18.1323 2.09083 17.5799 2.1266 17.1432 2.44513C15.5356 3.6178 14.4264 5.39555 13.4739 6.78755C13.0681 7.38061 13.1351 8.18514 13.6503 8.70036L15.0067 10.0567C14.6919 10.752 14.0496 11.9511 13.0005 13.0002C11.9514 14.0494 10.7523 14.6916 10.057 15.0065L8.70128 13.6507C8.18558 13.1351 7.37758 13.0671 6.78318 13.4789C5.36318 14.4627 3.69547 15.5562 2.47948 17.1135C2.13798 17.5509 2.08718 18.1229 2.29087 18.5983C3.12767 20.5509 5.24528 22.2138 7.44818 22.1327Z"
                      fill="#5D5D5D"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_856_256">
                      <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
                    </clipPath>
                  </defs>
                </svg>
                <a href="tel:+966507482022">
                  <span>+966507482022</span>
                </a>
              </div>
            </div>
            <div>
              <h4>{isArabic ? "راسلنا علي" : "Message us"}</h4>
              <div className="email">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g clip-path="url(#clip0_856_243)">
                    <path
                      d="M20 4C21.0544 4 21.9182 4.81588 21.9945 5.85074L22 6V18C22 19.0544 21.1841 19.9182 20.1493 19.9945L20 20H4C2.94564 20 2.08183 19.1841 2.00549 18.1493L2 18V6C2 4.94564 2.81588 4.08183 3.85074 4.00549L4 4H20ZM20 7.41424L13.0606 14.3536C12.4748 14.9394 11.5251 14.9394 10.9393 14.3536L4 7.41424V18H20V7.41424ZM18.5858 6H5.41409L12 12.5859L18.5858 6Z"
                      fill="#6B7280"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_856_243">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <a href="mailto:info@details.net.sa">
                  <span>info@details.net.sa</span>
                </a>
              </div>
            </div>
          </section>
        </section>
        <form className="contact-form" onSubmit={handelSubmit}>
          <input
            type="email"
            placeholder={`${isArabic ? "البريد الإلكتروني" : "Email"}`}
            required
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
          />
          <input
            type="number"
            placeholder={`${isArabic ? "رقم الهاتف" : "Phone Number"}`}
            required
            value={mobile}
            onChange={(ev) => setMobile(ev.target.value)}
          />

          <input
            type="text"
            placeholder={`${isArabic ? "الرسالة" : "Message"}`}
            required
            style={{ paddingBottom: "105px" }}
            value={message}
            onChange={(ev) => setMessage(ev.target.value)}
          />
          <button type="submit">{isArabic ? "إرسال" : "Send"}</button>
        </form>
      </section>
    </section>
  );
};

export default Contact;
