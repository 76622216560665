import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css``;

const Medium = css``;

const Large = css`
  display: flex;
  align-items: center;
  margin: 0 -10px;
  
  a.active {
    span {
      font-weight: 700;
    }
  }
`;

const XLarge = css``;

const XXLarge = css``;

export const SHeaderLinks = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
