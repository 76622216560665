import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  color: #6B7280;
  font-size: 20px;
  font-weight: 300;
  line-height: 150%;
  margin-top: 48px;
  
  p, ul {
    margin-bottom: 16px;
  }
  
  ul {
    ${({isArabic}) => isArabic ? "margin-right: 23px" : "margin-left: 23px"};
    
    li {
      margin-bottom: 5px;
      h2, h3, h4, h5, h6 {
        margin: 0;
      }
    }
  }
  
  h2, h3, h4, h5, h6 {
    margin: 24px 0 10px;
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SCompNewsDetailsText = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
