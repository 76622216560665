import { useLocale } from "providers/LocaleProvider";
import image from "assets/images/contact-us-image.png";
import { useRef, useState } from "react";
import API from "api";
import Button from "components/Button";
import SuccessModalMessage from "components/SuccessModalMessage";
import { SCompContactUs } from "./styles/SCompContactUs";
import { SCompContactUsCard } from "./styles/SCompContactUsCard";
import { SCompContactUsCards } from "./styles/SCompContactUsCards";
import { SCompContactUsCardText } from "./styles/SCompContactUsCardText";
import { SCompContactUsCardTextAndIcon } from "./styles/SCompContactUsCardTextAndIcon";
import { SCompContactUsCardTitle } from "./styles/SCompContactUsCardTitle";
import { SCompContactUsContainer } from "./styles/SCompContactUsContainer";
import { SCompContactUsForm } from "./styles/SCompContactUsForm";
import { SCompContactUsFormAndCards } from "./styles/SCompContactUsFormAndCards";
import { SCompContactUsFormInputCont } from "./styles/SCompContactUsFormInputCont";
import { SCompContactUsImage } from "./styles/SCompContactUsImage";
import { SCompContactUsImageCont } from "./styles/SCompContactUsImageCont";
import { SCompContactUsWrapper } from "./styles/SCompContactUsWrapper";

const CompContactUs = () => {
  const { isArabic } = useLocale();
  const emailRef = useRef(null);
  const mobileRef = useRef(null);
  const subjectRef = useRef(null);
  const messageRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsOpen(false);

    try {
      const response = await API.post("/contactus/send", {
        email: emailRef.current.value,
        subject: subjectRef.current.value,
        mobile: mobileRef.current.value,
        message: messageRef.current.value,
      });
      if (response?.data?.status) {
        setIsOpen(true);
        emailRef.current.value = "";
        subjectRef.current.value = "";
        mobileRef.current.value = "";
        messageRef.current.value = "";
      }
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <SCompContactUs className="contact-us">
      <SCompContactUsContainer isArabic={isArabic}>
        <SCompContactUsWrapper>
          <SCompContactUsImageCont>
            <SCompContactUsImage>
              <img src={image} alt="contact us" />
            </SCompContactUsImage>
          </SCompContactUsImageCont>
          <SCompContactUsFormAndCards isArabic={isArabic}>
            <SCompContactUsCards>
              <SCompContactUsCard isArabic={isArabic}>
                <SCompContactUsCardTitle>{isArabic ? "أتصل بنا" : "Contact Us"}</SCompContactUsCardTitle>
                <SCompContactUsCardTextAndIcon isArabic={isArabic}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_805_38)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.8284 15.8285C11.9826 19.6743 8.41979 20.0954 7.37409 20.1339C6.10959 20.1804 4.81979 19.1483 4.26239 18.0906C5.15199 17.0465 6.31079 16.2367 7.58049 15.3587C8.32879 16.107 9.25159 17.4973 10.4814 16.9999C11.1797 16.7175 12.9059 15.9226 14.4142 14.4143C15.9226 12.9059 16.7175 11.1797 16.9999 10.4815C17.4977 9.25061 16.1043 8.32612 15.3554 7.57726C16.2329 6.28657 17.0294 5.09818 18.0713 4.2532C19.1427 4.81369 20.1809 6.0937 20.1338 7.37412C20.0953 8.41978 19.6742 11.9827 15.8284 15.8285ZM7.44769 22.1325C8.88859 22.0795 12.9693 21.5161 17.2426 17.2427C21.516 12.9693 22.0794 8.88862 22.1325 7.44767C22.2133 5.25221 20.5315 3.11902 18.5878 2.28605C18.1318 2.09059 17.5794 2.12636 17.1427 2.44489C15.5351 3.61756 14.4259 5.39531 13.4734 6.78731C13.0676 7.38037 13.1346 8.1849 13.6498 8.70012L15.0062 10.0565C14.6914 10.7518 14.0491 11.9509 13 13C11.9509 14.0492 10.7518 14.6914 10.0565 15.0063L8.70079 13.6505C8.18509 13.1349 7.37709 13.0669 6.78269 13.4787C5.36269 14.4625 3.69499 15.556 2.47899 17.1133C2.13749 17.5507 2.08669 18.1227 2.29039 18.5981C3.12719 20.5507 5.24479 22.2136 7.44769 22.1325Z"
                        fill="#5D5D5D"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_805_38">
                        <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
                      </clipPath>
                    </defs>
                  </svg>
                  <SCompContactUsCardText href="tel: +966507482022">+966507482022</SCompContactUsCardText>
                </SCompContactUsCardTextAndIcon>
              </SCompContactUsCard>
              <SCompContactUsCard>
                <SCompContactUsCardTitle>{isArabic ? "راسلنا علي" : "Message Us"}</SCompContactUsCardTitle>
                <SCompContactUsCardTextAndIcon isArabic={isArabic}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_805_25)">
                      <path
                        d="M20 4C21.0544 4 21.9182 4.81588 21.9945 5.85074L22 6V18C22 19.0544 21.1841 19.9182 20.1493 19.9945L20 20H4C2.94564 20 2.08183 19.1841 2.00549 18.1493L2 18V6C2 4.94564 2.81588 4.08183 3.85074 4.00549L4 4H20ZM20 7.41424L13.0606 14.3536C12.4748 14.9394 11.5251 14.9394 10.9393 14.3536L4 7.41424V18H20V7.41424ZM18.5858 6H5.41409L12 12.5859L18.5858 6Z"
                        fill="#6B7280"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_805_25">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <SCompContactUsCardText href="mailto:info@details.net.sa">info@details.net.sa</SCompContactUsCardText>
                </SCompContactUsCardTextAndIcon>
              </SCompContactUsCard>
            </SCompContactUsCards>
            <SCompContactUsForm onSubmit={onSubmit}>
              <SCompContactUsFormInputCont isArabic={isArabic}>
                <label htmlFor="email">{isArabic ? "البريد الإلكتروني" : "Email"}</label>
                <input type="email" id="email" ref={emailRef} name="email" required />
              </SCompContactUsFormInputCont>
              <SCompContactUsFormInputCont isArabic={isArabic}>
                <label htmlFor="mobile">{isArabic ? "رقم الجوال" : "Mobile"}</label>
                <input type="text" id="mobile" ref={mobileRef} name="mobile" required />
              </SCompContactUsFormInputCont>
              <SCompContactUsFormInputCont isArabic={isArabic}>
                <label htmlFor="subject">{isArabic ? "الموضوع" : "Subject"}</label>
                <input type="text" id="subject" ref={subjectRef} name="subject" required />
              </SCompContactUsFormInputCont>
              <SCompContactUsFormInputCont isArabic={isArabic}>
                <label htmlFor="message">{isArabic ? "الرسالة" : "Message"}</label>
                <textarea id="message" ref={messageRef} name="message" required></textarea>
              </SCompContactUsFormInputCont>
              <Button disabled={isLoading} as="button">
                {isArabic ? "أرسل" : "Send"}
              </Button>
            </SCompContactUsForm>
          </SCompContactUsFormAndCards>
        </SCompContactUsWrapper>
        <SuccessModalMessage
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          title={isArabic ? "تم إرسال الرسالة بنجاح !" : "Message has been sent successfully!"}
          text={
            isArabic
              ? "يسعدنا تلقي رسالتك وسنقوم بإهتمام بمحاولة الرد عليكم في خلال 24مدة أقصاها  ساعة."
              : "We are happy to receive your message and we will carefully try to respond to you within a maximum of 24 hours."
          }
          icon={
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
              <g clipPath="url(#clip0_814_358)">
                <path
                  d="M39.9998 6.66675C58.4092 6.66675 73.3332 21.5906 73.3332 40.0001C73.3332 58.4094 58.4092 73.3334 39.9998 73.3334C21.5903 73.3334 6.6665 58.4094 6.6665 40.0001C6.6665 21.5906 21.5903 6.66675 39.9998 6.66675ZM51.7845 27.9379L35.2852 44.4371L28.2142 37.3661C26.9125 36.0644 24.8019 36.0644 23.5002 37.3661C22.1984 38.6677 22.1984 40.7784 23.5002 42.0801L32.6926 51.2724C34.1245 52.7044 36.4462 52.7044 37.8782 51.2724L56.4985 32.652C57.8002 31.3502 57.8002 29.2397 56.4985 27.9379C55.1968 26.6362 53.0862 26.6362 51.7845 27.9379Z"
                  fill="#FD5D63"
                />
              </g>
              <defs>
                <clipPath id="clip0_814_358">
                  <rect width="80" height="80" fill="white" />
                </clipPath>
              </defs>
            </svg>
          }
        />
      </SCompContactUsContainer>
      <div style={{ width: "100%" }}>
        <iframe
          width="100%"
          height="470"
          title="Details Digital"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=470&amp;hl=en&amp;q=26.405690,%2050.061790+(Details%20Digital)&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>
      </div>
    </SCompContactUs>
  );
};

export default CompContactUs;
