import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "constants/mediaquerys";

const CSSEnglish = css`
  left: 225px;
  top: 490px;
  right: unset;
  transform: scaleX(-1);
`;

const Small = css`
  display: none;
`;

const Medium = css``;

const Large = css`
  display: block;
  position: absolute;
  top: 367px;
  left: 383px;

  ${({ isArabic }) => !isArabic && CSSEnglish};
`;

const XLarge = css``;

const XXLarge = css``;

export const SHeroBottomLeftSvg = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
