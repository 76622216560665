import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  flex-shrink: 0;
  width: 100%;
  margin-top: 40px;
`;

const Medium = css`
  width: 49%;
  ${({isArabic}) => isArabic ? "margin-right: 76px" : "margin-left: 76px"};
`;

const Large = css`
`;

const XLarge = css`
`;

const XXLarge = css``;

export const SCompTextImageLeftImgWrapper = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
