import React, { useEffect } from "react";
import { useLocale } from "providers/LocaleProvider";
import { Link } from "react-router-dom";
import { SHero } from "./styles/SHero";
import { SHeroBottomSvg } from "./styles/SHeroBottomSvg";
import { SHeroButton } from "./styles/SHeroButton";
import { SHeroContainer } from "./styles/SHeroContainer";
import { SHeroContent } from "./styles/SHeroContent";
import { SHeroLines } from "./styles/SHeroLines";
import { SHeroText } from "./styles/SHeroText";
import { SHeroTitle } from "./styles/SHeroTitle";
import { SHeroTopSvg } from "./styles/SHeroTopSvg";
import AOS from "aos";
import "aos/dist/aos.css";
import "./styles/animate.css";
import circle from "../../assets/images/circle.svg";
import { SHeroTopLeftSvg } from "./styles/SHeroTopLeftSvg";
import { SHeroBottomLeftSvg } from "./styles/SHeroBottomLeftSvg";

const Hero = () => {
  const { isArabic, locale } = useLocale();
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <SHero className="hero">
      <SHeroContainer>
        <SHeroContent data-aos="fade-left">
          <SHeroTitle>{isArabic ? "نهتم بتفاصيل أعمالك" : "We care about the details of your business"}</SHeroTitle>
          <SHeroText>
            {isArabic
              ? "تقدم ديتيلز ديجتال بسواعد وطنية خدمات تطوير البرمجيات، والبنية التحتية، والاسناد والتشغيل، بالإضافة إلى استشارات التحول الرقمي وعدد من المنتجات الموجهة لدعم ذكاء الأعمال ."
              : "Details Digital provides, with national resources, software development, infrastructure, support and operation services, in addition to digital transformation consulting and a number of products directed at supporting business intelligence."}
          </SHeroText>
        </SHeroContent>
        <Link to={`/${locale}#digital-future`}>
          <SHeroButton>
            <svg
              width="55"
              height="55"
              viewBox="0 0 55 55"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="down-arrow"
            >
              <g clipPath="url(#clip0_122_28)">
                <path
                  d="M49.969 27.2567C49.969 31.7491 48.6369 36.1405 46.141 39.8758C43.6452 43.611 40.0978 46.5222 35.9474 48.2413C31.7969 49.9604 27.23 50.4102 22.824 49.5337C18.4179 48.6572 14.3708 46.4939 11.1943 43.3172C8.01778 40.1406 5.85461 36.0933 4.97831 31.6873C4.10202 27.2812 4.55197 22.7143 6.27125 18.5639C7.99054 14.4136 10.9019 10.8663 14.6373 8.37064C18.3726 5.87497 22.7642 4.54302 27.2565 4.54321C33.2803 4.54348 39.0573 6.93662 43.3167 11.1962C47.5761 15.4558 49.969 21.2329 49.969 27.2567Z"
                  stroke="#FD5D63"
                  strokeWidth="1.49997"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  opacity="0.75"
                  d="M19.2378 24.3936L27.2552 32.392L35.2726 24.3936"
                  stroke="#FD5D63"
                  strokeWidth="1.49997"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_122_28">
                  <rect width="54.51" height="54.51" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </SHeroButton>
        </Link>
      </SHeroContainer>
      <SHeroLines isArabic={isArabic}>
        <img src={circle} alt="circle" className="down-arrow" />
      </SHeroLines>
      <SHeroTopLeftSvg isArabic={isArabic}>
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none" className="svg">
          <path
            d="M2.13192 12.2725C1.86492 12.0455 0.941887 11.3125 1.00289 10.3795C1.05289 9.61848 1.88091 8.92746 3.40291 7.91246C19.9309 -3.14954 37.4529 -0.63551 44.4919 13.3125C49.1429 22.5315 47.4999 33.1635 40.5619 44.1775C39.5459 45.7905 38.6249 46.7955 37.5889 46.8495C37.1234 46.8088 36.6721 46.6687 36.2655 46.4386C35.8588 46.2084 35.5063 45.8936 35.2319 45.5155C32.2829 42.1995 5.33892 14.9935 2.13192 12.2725Z"
            stroke="#5B4894"
            stroke-miterlimit="10"
          />
        </svg>
      </SHeroTopLeftSvg>
      <SHeroBottomSvg isArabic={isArabic}>
        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none" className="svg">
          <path
            d="M34.382 1.11301C34.6228 0.791887 34.9299 0.526293 35.2824 0.334259C35.6349 0.142225 36.0246 0.028235 36.425 0C37.647 0.1 38.376 1.71301 38.756 2.29001C49.24 18.227 46.431 35.406 32.65 42.567C23.544 47.299 13.183 45.905 2.54999 39.344C1.02399 38.401 0.067 37.536 0 36.544C0.0387224 36.0719 0.179397 35.6139 0.412292 35.2014C0.645188 34.789 0.96476 34.432 1.349 34.155C6.701 29.411 11.479 24.055 16.627 19.092C19.081 16.733 31.24 4.53201 34.382 1.11301Z"
            fill="#F05E65"
          />
        </svg>
      </SHeroBottomSvg>
      <SHeroBottomLeftSvg isArabic={isArabic}>
        <svg xmlns="http://www.w3.org/2000/svg" width="55" height="50" viewBox="0 0 55 50" fill="none" className="svg">
          <path
            d="M22.939 17.9496C15.829 23.5496 8.798 29.2496 1.565 34.6936C-0.866002 36.5226 -0.345964 37.4646 2.30304 39.6996C19.103 53.8746 39.151 53.0076 49.229 38.2156C55.889 28.4416 55.71 16.3936 49.653 3.33363C48.159 0.111629 46.694 -1.15536 43.885 1.25064C37.103 7.05764 29.93 12.3936 22.915 17.9196L22.939 17.9496Z"
            fill="#5A4893"
          />
        </svg>
      </SHeroBottomLeftSvg>
      <SHeroTopSvg isArabic={isArabic} style={{ left: "500px" }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none" className="svg">
          <path
            d="M35.382 2.11301C35.6228 1.79189 35.9299 1.52629 36.2824 1.33426C36.6349 1.14223 37.0246 1.02823 37.425 1C38.647 1.1 39.376 2.71401 39.756 3.29001C50.24 19.227 47.431 36.406 33.65 43.567C24.544 48.299 14.183 46.906 3.54999 40.344C2.02399 39.402 1.067 38.536 1 37.544C1.03846 37.0719 1.17901 36.6137 1.41193 36.2012C1.64484 35.7888 1.96457 35.4318 2.349 35.155C7.701 30.411 12.479 25.055 17.627 20.091C20.081 17.733 32.24 5.53301 35.382 2.11301Z"
            stroke="#F05E65"
            stroke-miterlimit="10"
          />
        </svg>
      </SHeroTopSvg>
    </SHero>
  );
};

export default Hero;
