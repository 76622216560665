import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css``;

const Medium = css``;

const Large = css`
  ${({isArabic}) => isArabic ? "margin-left: 40px" : "margin-right: 40px"};
  width: 73.58%;
  flex-shrink: 0;
`;

const XLarge = css``;

const XXLarge = css``;

export const SCompNewsCards = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
