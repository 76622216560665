export const getFormattedTimeDifference = (date, isArabic) => {
  const currentDate = new Date();
  const backendDateTime = new Date(date);
  const timeDifferenceInSeconds = Math.floor((currentDate - backendDateTime) / 1000); // Difference in seconds
  
  if (timeDifferenceInSeconds < 60) {
    return isArabic ? `منذ ${timeDifferenceInSeconds} ثوانى` : `${timeDifferenceInSeconds} seconds ago`;
  }
  
  const timeDifferenceInMinutes = Math.floor(timeDifferenceInSeconds / 60); // Difference in minutes
  
  if (timeDifferenceInMinutes < 60) {
    return isArabic ? `منذ ${timeDifferenceInMinutes} دقيقة` : `${timeDifferenceInMinutes} minutes ago`;
  }
  
  const timeDifferenceInHours = Math.floor(timeDifferenceInMinutes / 60); // Difference in hours
  
  if (timeDifferenceInHours < 24) {
    return isArabic ? `منذ ${timeDifferenceInHours} ساعة` : `${timeDifferenceInHours} hours ago`;
  }
  
  const timeDifferenceInDays = Math.floor(timeDifferenceInHours / 24); // Difference in days
  
  if (timeDifferenceInDays < 7) {
    return `${timeDifferenceInDays} days ago`;
  }
  
  const timeDifferenceInWeeks = Math.floor(timeDifferenceInDays / 7); // Difference in weeks
  
  if (timeDifferenceInWeeks < 4) {
    return `${timeDifferenceInWeeks} weeks ago`;
  }
  
  const timeDifferenceInMonths = Math.floor(timeDifferenceInWeeks / 4); // Difference in months
  
  if (timeDifferenceInMonths < 12) {
    return isArabic ? `منذ ${timeDifferenceInMonths} شهر` : `${timeDifferenceInMonths} months ago`;
  }
  
  const timeDifferenceInYears = Math.floor(timeDifferenceInMonths / 12); // Difference in years
  return isArabic ? `منذ ${timeDifferenceInYears} سنة` : `${timeDifferenceInYears} years ago`;
}
