import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "constants/mediaquerys";

const Small = css`
  img {
    height: 300px;
    width: 100%;
    object-fit: contain;
    margin: auto;
    display: block;
  }
`;

const Medium = css`
  img {
    height: 358px;
    width: 358px;
    object-fit: contain;
  }
`;

const Large = css`
  img {
    height: 508px;
    width: 508px;
    object-fit: contain;
  }
`;

const XLarge = css``;

const XXLarge = css``;

export const SCompTextImageRightImg = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
