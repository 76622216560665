import { useEffect, useRef, useState } from "react";
import { fetchData } from "helpers/functions/fetchData";
import { useLoader } from "providers/LoaderProvider";
import SectionTitle from "components/SectionTitle";
import { useLocation } from "react-router-dom";
import { useLocale } from "providers/LocaleProvider";
import { SCompCompanyServices } from "./styles/SCompCompanyServices";
import { SCompCompanyServicesCard } from "./styles/SCompCompanyServicesCard";
import { SCompCompanyServicesCardIcon } from "./styles/SCompCompanyServicesCardIcon";
import { SCompCompanyServicesCardText } from "./styles/SCompCompanyServicesCardText";
import { SCompCompanyServicesCardTitle } from "./styles/SCompCompanyServicesCardTitle";
import { SCompCompanyServicesContainer } from "./styles/SCompCompanyServicesContainer";
import { SCompCompanyServicesWrapper } from "./styles/SCompCompanyServicesWrapper";
import AOS from "aos";
import "aos/dist/aos.css";

const CompCompanyServices = () => {
  const [data, setData] = useState([]);
  const { openLoader, closeLoader } = useLoader();
  const { isArabic, locale } = useLocale();
  const location = useLocation();
  const ref = useRef(null);

  const fetchDataAsync = async () => {
    openLoader();
    try {
      const response = await fetchData("/services", locale);
      setData(response);
      closeLoader();
    } catch {
      closeLoader();
    }
  };

  useEffect(() => {
    fetchDataAsync();
  }, [locale]);

  useEffect(() => {
    if (location.hash === "#services") {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location.hash]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <SCompCompanyServices ref={ref} className="company-services" id="services">
      <SCompCompanyServicesContainer>
        <SectionTitle isCenter>{isArabic ? "خدمات الشركة" : "Our Services"}</SectionTitle>
        <SCompCompanyServicesWrapper>
          {data?.map((card) => (
            <SCompCompanyServicesCard key={card.id} isArabic={isArabic} data-aos="fade-up">
              <SCompCompanyServicesCardIcon>
                {card?._embedded?.["wp:featuredmedia"]?.[0]?.source_url && (
                  <img src={card._embedded["wp:featuredmedia"][0].source_url} alt="" />
                )}
              </SCompCompanyServicesCardIcon>
              <SCompCompanyServicesCardTitle>{card.title.rendered}</SCompCompanyServicesCardTitle>
              <SCompCompanyServicesCardText dangerouslySetInnerHTML={{ __html: card.excerpt.rendered }} />
            </SCompCompanyServicesCard>
          ))}
        </SCompCompanyServicesWrapper>
      </SCompCompanyServicesContainer>
    </SCompCompanyServices>
  );
};

export default CompCompanyServices;
