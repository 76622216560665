import {useRef, useState} from "react";
import Button from "components/Button";
import {useLocale} from "providers/LocaleProvider";
import {SCompNewsletter} from "./styles/SCompNewsletter";
import {SCompNewsletterContainer} from "./styles/SCompNewsletterContainer";
import {SCompNewsletterSvg} from "./styles/SCompNewsletterSvg";
import {SCompNewsletterText} from "./styles/SCompNewsletterText";
import {SCompNewsletterTitle} from "./styles/SCompNewsletterTitle";
import {SCompNewsletterTitleAndForm} from "./styles/SCompNewsletterTitleAndForm";
import {SCompNewsletterWrapper} from "./styles/SCompNewsletterWrapper";
import API from 'api';
import image from "assets/images/newsletter-svg.png";

const CompNewsletter = () => {
  const inputRef = useRef(null);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {isArabic} = useLocale();
  
  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true)
    API.post('/newsletter/save', {email: inputRef.current.value})
        .then(result => {
          setIsLoading(false);
          if (result.data?.status) {
            setMessage(isArabic ? "تم الإشتراك بنجاح" : "You have successfully subscribed")
          }
        })
        .catch(error => {
          setIsLoading(false);
          setMessage(isArabic ? "حدث خطأ" : "An error occurred")
          console.error('Error:', error);
        });
  }
  return (
      <SCompNewsletter className="newsletter">
        <SCompNewsletterSvg>
          <img src={image} alt="details digital"/>
        </SCompNewsletterSvg>
        <SCompNewsletterContainer>
          <SCompNewsletterTitleAndForm>
            <SCompNewsletterTitle>
              {isArabic ? " اشترك في نشرة Details Digital" : "Subscribe to the Details Digital newsletter"}
            </SCompNewsletterTitle>
            <SCompNewsletterWrapper onSubmit={onSubmit} isArabic={isArabic}>
              <input type="email" id="email" name="email" ref={inputRef} placeholder={isArabic ? "البريد الإلكتروني" : "Email Address"} required/>
              <Button as="button" style={{minWidth: '130px'}} disabled={isLoading}>
                {isArabic ? "اشترك" : "Subscribe"}
              </Button>
            </SCompNewsletterWrapper>
            {message && (
                <SCompNewsletterText>
                  {message}
                </SCompNewsletterText>
            )}
          </SCompNewsletterTitleAndForm>
        </SCompNewsletterContainer>
      </SCompNewsletter>
  );
};

export default CompNewsletter;
