import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  background: #ffffff;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SCompRelatedProducts = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
