import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
`;

const Medium = css``;

const Large = css`
  background: #F3F2F1;
  color: #898989;
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  border-radius: 50px;
  padding: 8px 12px;
  margin: 4px;
`;

const XLarge = css``;

const XXLarge = css``;

export const SCompNewsSidebarMark = styled.p`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
