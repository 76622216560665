import React from "react";
import { Link } from "react-router-dom";
import LearnMoreBtn from "components/LearnMoreBtn";
import { useLocale } from "providers/LocaleProvider";
import { SNewsCard } from "./styles/SNewsCard";
import { SNewsCardIcon } from "./styles/SNewsCardIcon";
import { SNewsCardText } from "./styles/SNewsCardText";
import { SNewsCardTitle } from "./styles/SNewsCardTitle";

const NewsCard = ({ data }) => {
  const { locale } = useLocale();
  return (
    <SNewsCard data-aos="fade-up">
      <SNewsCardIcon>
        {data?._embedded?.["wp:featuredmedia"]?.[0]?.source_url && (
          <img src={data._embedded["wp:featuredmedia"][0].source_url} alt="" />
        )}
      </SNewsCardIcon>
      <Link to={`/${locale}/news/${data.id}`}>
        <SNewsCardTitle>{data.title.rendered}</SNewsCardTitle>
      </Link>
      <SNewsCardText dangerouslySetInnerHTML={{ __html: data.excerpt.rendered }} />
      <Link to={`/${locale}/news/${data.id}`}>
        <LearnMoreBtn />
      </Link>
    </SNewsCard>
  );
};

export default NewsCard;
