import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  background: #000000;
  padding: 16px 20px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
`;

const Medium = css``;

const Large = css``;

const XLarge = css`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 80px;
`;

const XXLarge = css``;

export const SCompFooterLinksAndCopyrights = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
