import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  color: #000;
  font-size: 20px;
  font-weight: 300;
  line-height: 172.5%;
  margin-top: 18px;
`;

const Medium = css`
  opacity: 0.8;
`;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SHeroText = styled.p`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
