import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  display: none;
`;

const Medium = css``;

const Large = css`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 400px;
  img {
    width: 100%;
    object-fit: contain;
  }
`;

const XLarge = css``;

const XXLarge = css``;

export const SCompNewsletterSvg = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
