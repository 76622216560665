import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "constants/mediaquerys";

const Small = css`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  cursor: pointer;
`;

const Medium = css``;

const Large = css`
  margin-top: 120px;
  position: relative;
  top: 80px;
`;

const XLarge = css``;

const XXLarge = css``;

export const SHeroButton = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
