import React from "react";
import { Link } from "react-router-dom";
import logo from "assets/images/logo.png";
import LearnMoreBtn from "components/LearnMoreBtn";
import { useLocale } from "providers/LocaleProvider";
import { SCompProductCard } from "./styles/SCompProductCard";
import { SCompProductCardIcon } from "./styles/SCompProductCardIcon";
import { SCompProductCardText } from "./styles/SCompProductCardText";
import { SCompProductCardTitle } from "./styles/SCompProductCardTitle";

const ProductCard = ({ data, isWhite }) => {
  const { locale, isArabic } = useLocale();
  return (
    <SCompProductCard isArabic={isArabic} isWhite={isWhite} data-aos="fade-up">
      <SCompProductCardIcon>
        <img src={data?._embedded?.["wp:featuredmedia"]?.[0]?.source_url ?? logo} alt="" loading="lazy" />
      </SCompProductCardIcon>
      <Link to={`/${locale}/products/${data.id}`}>
        <SCompProductCardTitle>{data.title.rendered}</SCompProductCardTitle>
      </Link>
      <SCompProductCardText dangerouslySetInnerHTML={{ __html: data.excerpt.rendered }} />
      <Link to={`/${locale}/products/${data.id}`}>
        <LearnMoreBtn />
      </Link>
    </SCompProductCard>
  );
};

export default ProductCard;
