import React, { useEffect, useState } from "react";
import "./styles/product.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getProductName } from "store/productReducer";

const ProductsBanner = ({ data, locale, isArabic }) => {
  const { title, content } = data;
  const dispatch = useDispatch();
  useEffect(() => {
    const aa = () => {
      dispatch(getProductName(title.rendered));
    };
    aa();
  }, []);
  return (
    <>
      <section className={`${!isArabic ? "en" : "ar"} product-banner`}>
        <Link to={`/${locale}/products`} className="back">
          <span>{isArabic ? "العودة إلي المنتجات" : "Return To Products"}</span>
          {isArabic ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="37"
              height="8"
              viewBox="0 0 37 8"
              fill="none"
              style={{ marginRight: "16px" }}
            >
              <path
                d="M0.646446 3.64644C0.451183 3.84171 0.451183 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.73079 4.34027 7.73079 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82842L1.70711 4L4.53553 1.17157C4.7308 0.976308 4.7308 0.659725 4.53553 0.464463C4.34027 0.269201 4.02369 0.269201 3.82843 0.464463L0.646446 3.64644ZM37 3.5L1 3.5L1 4.5L37 4.5L37 3.5Z"
                fill="white"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="37"
              height="8"
              viewBox="0 0 37 8"
              fill="none"
              style={{ marginLeft: "16px" }}
            >
              <path
                d="M36.3536 4.35355C36.5488 4.15829 36.5488 3.84171 36.3536 3.64644L33.1716 0.464463C32.9763 0.269201 32.6597 0.269201 32.4645 0.464463C32.2692 0.659725 32.2692 0.976308 32.4645 1.17157L35.2929 4L32.4645 6.82842C32.2692 7.02369 32.2692 7.34027 32.4645 7.53553C32.6597 7.73079 32.9763 7.73079 33.1716 7.53553L36.3536 4.35355ZM0 4.5H36V3.5H0V4.5Z"
                fill="white"
              />
            </svg>
          )}
        </Link>
        <div>
          <h2>{title.rendered}</h2>
          <p dangerouslySetInnerHTML={{ __html: content.rendered.slice(0, 300) }} className="description" />
        </div>
      </section>
    </>
  );
};

export default ProductsBanner;
