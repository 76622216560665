import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  display: flex;
  padding-bottom: 24px;
  margin-bottom: 50px;
  border-bottom: 1px solid rgba(16, 22, 45, 0.24);
  
  &:last-of-type {
    margin-bottom: 32px;
  }
  
  &, input, label, textarea {
    color: #000;
    font-size: 20px;
    font-weight: 300;
    line-height: 150%;
  }
  
  input, textarea {
    background: none;
    padding: 0;
    margin: 0;
    outline: 0;
    border: 0;
    ${({isArabic}) => isArabic ? "padding-right: 12px" : "padding-left: 12px"};
    flex-grow: 1;
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SCompContactUsFormInputCont = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
