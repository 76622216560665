import {useEffect, useState} from "react";
import {fetchData} from "helpers/functions/fetchData";
import {useLoader} from "providers/LoaderProvider";
import {useLocale} from "providers/LocaleProvider";
import ProductCard from "components/ProductCard";
import {SCompProducts} from "./styles/SCompProducts";
import {SCompProductsContainer} from "./styles/SCompProductsContainer";
import {SCompProductsWrapper} from "./styles/SCompProductsWrapper";

const CompProducts = () => {
  const [data, setData] = useState([]);
  const {openLoader, closeLoader} = useLoader();
  const {locale} = useLocale();
  
  const fetchDataAsync = async () => {
    openLoader();
    try {
      const response = await fetchData('/products', locale);
      setData(response);
      closeLoader();
    } catch {
      closeLoader();
    }
  }
  
  useEffect(() => {
    fetchDataAsync();
  }, [locale]);
  
  return (
      <SCompProducts className="company-products">
        <SCompProductsContainer>
          <SCompProductsWrapper>
            {data?.map(card => (
                <ProductCard key={card.id} data={card}/>
            ))}
          </SCompProductsWrapper>
        </SCompProductsContainer>
      </SCompProducts>
  );
};

export default CompProducts;
