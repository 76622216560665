import { useEffect, useState } from "react";
import { fetchData } from "helpers/functions/fetchData";
import { useLoader } from "providers/LoaderProvider";
import { useLocale } from "providers/LocaleProvider";
import { useParams } from "react-router-dom";
import Button from "components/Button";
import { SCompProductDetails } from "./styles/SCompProductDetails";
import { SCompProductDetailsButtons } from "./styles/SCompProductDetailsButtons";
import { SCompProductDetailsFeature } from "./styles/SCompProductDetailsFeature";
import { SCompProductDetailsFeatures } from "./styles/SCompProductDetailsFeatures";
import { SCompProductDetailsFeatureText } from "./styles/SCompProductDetailsFeatureText";
import { SCompProductDetailsScreensAndFeatures } from "./styles/SCompProductDetailsScreensAndFeatures";
import { SCompProductDetailsContainer } from "./styles/SCompProductDetailsContainer";
import { SCompProductDetailsWrapper } from "./styles/SCompProductDetailsWrapper";
import SwiperCore, { Autoplay, Pagination } from "swiper/core";
import "swiper/swiper-bundle.css";
import ProductsBanner from "./ProductsBanner";

SwiperCore.use([Autoplay, Pagination]);

const CompProductDetails = () => {
  const [data, setData] = useState();
  const { openLoader, closeLoader } = useLoader();
  const { locale, isArabic } = useLocale();
  const { id } = useParams();

  const fetchDataAsync = async () => {
    openLoader();
    try {
      const response = await fetchData("/products", locale, `&include=${id}&acf_format=standard`);
      setData(response[0]);
      closeLoader();
    } catch (err) {
      closeLoader();
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDataAsync();
  }, [locale, id]);

  if (!data) return <></>;

  return (
    <SCompProductDetails className="company-product-details">
      <ProductsBanner data={data} locale={locale} isArabic={isArabic} />
      <SCompProductDetailsContainer isArabic={isArabic}>
        <SCompProductDetailsScreensAndFeatures>
          <SCompProductDetailsButtons>
            <Button as="button">{isArabic ? "المميزات" : "Advantages"}</Button>
          </SCompProductDetailsButtons>
          <SCompProductDetailsWrapper>
            <SCompProductDetailsFeatures>
              {data?.acf?.features?.map((feature, index) => (
                <SCompProductDetailsFeature key={`${index}-${feature.feature}-${locale}`} isArabic={isArabic}>
                  {feature.icon && <img src={feature.icon} alt={feature.feature} />}
                  {feature.feature && (
                    <SCompProductDetailsFeatureText>{feature.feature}</SCompProductDetailsFeatureText>
                  )}
                </SCompProductDetailsFeature>
              ))}
            </SCompProductDetailsFeatures>
          </SCompProductDetailsWrapper>
        </SCompProductDetailsScreensAndFeatures>
      </SCompProductDetailsContainer>
    </SCompProductDetails>
  );
};

export default CompProductDetails;
