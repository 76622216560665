import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  width: 32px;
  height: 32px;
  cursor: pointer;
  
  svg {
    width: 100%;
    height: 100%;
  }
`;

const Medium = css``;

const Large = css`
  display: none;
`;

const XLarge = css``;

const XXLarge = css``;

export const SHeaderBurgerMenu = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
