import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  background: ${({isWhite}) => isWhite ? "#FFF" : "#F5F5F5"};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  padding: ${({isArabic}) => isArabic ? "40px 37px 20px 10.49px" : "40px 10.49px 20px 37px"};
  display: flex;
  flex-direction: column;
  
  a:last-of-type {
    margin-top: auto;
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SCompProductCard = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
