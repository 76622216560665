import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "constants/mediaquerys";

const CSSEnglish = css`
  margin-left: 0;
  margin-right: 27px;
  transform: scaleX(-1);
`;

const Small = css`
  flex-shrink: 0;
  width: 100%;
  margin-top: 40px;
`;

const Medium = css`
  width: 31%;
  margin-left: 27px;
  ${({ isArabic }) => !isArabic && CSSEnglish};
`;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SCompTextImageRightImgWrapper = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
