import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "constants/mediaquerys";

const Small = css`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 60px;
  gap: 40px;
`;

const Medium = css`
  grid-template-columns: repeat(2, 1fr);
`;

const Large = css`
  grid-template-columns: repeat(4, 1fr);
  gap: 90px;
`;

const XLarge = css``;

const XXLarge = css``;

export const SCompStatisticsWrapper = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
