import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  background: #ffffff;
  padding: 47px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  a {
    display: flex;
    align-items: center;
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 150%;
  
  }
  
  svg {
    ${({isArabic}) => isArabic ? "margin-right: 16px" : "margin-left: 16px"};
    ${({isArabic}) => !isArabic && "transform: rotate(180deg)"};
  }
`;

const Medium = css``;

const Large = css`
  padding: 47px 135px;
`;

const XLarge = css``;

const XXLarge = css``;

export const SCompJobDetailsHero = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
