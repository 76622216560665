import SectionTitle from "components/SectionTitle";
import { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useLocale } from "providers/LocaleProvider";
import { SCompTextImageRight } from "./styles/SCompTextImageRight";
import { SCompTextImageRightContainer } from "./styles/SCompTextImageRightContainer";
import { SCompTextImageRightContent } from "./styles/SCompTextImageRightContent";
import { SCompTextImageRightImg } from "./styles/SCompTextImageRightImg";
import image from "assets/images/developer.png";
import { SCompTextImageRightImgWrapper } from "./styles/SCompTextImageRightImgWrapper";
import { SCompTextImageRightLink } from "./styles/SCompTextImageRightLink";
import { SCompTextImageRightText } from "./styles/SCompTextImageRightText";
import { SCompTextImageRightWrapper } from "./styles/SCompTextImageRightWrapper";

const CompTextImageRight = () => {
  const { isArabic, locale } = useLocale();
  const location = useLocation();
  const ref = useRef(null);

  useEffect(() => {
    if (location.hash === "#digital-future") {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location.hash]);

  return (
    <SCompTextImageRight className="test-image-right" ref={ref} id="digital-future">
      <SCompTextImageRightContainer isArabic={isArabic}>
        <SCompTextImageRightWrapper>
          <SCompTextImageRightImgWrapper isArabic={isArabic}>
            <SCompTextImageRightImg>
              <img src={image} alt="developer" loading="lazy" />
            </SCompTextImageRightImg>
          </SCompTextImageRightImgWrapper>
          <SCompTextImageRightContent>
            <SectionTitle>{isArabic ? "حلول مبتكرة" : "Trade solutions"}</SectionTitle>
            <SCompTextImageRightText>
              {isArabic
                ? "نعمل على تقديم حلول تلبي احتياجات عملائنا، وذلك من خلال نخبة من العقول الوطنية عبر تقديم عددٍ من الخدمات الإلكترونية المبتكرة والحلول التقنية المتكاملة لدعم رحلة التحول في أداء القطاعين العام والخاص، والمساهمة في رفع مستوى الخدمات وضمان الكفاءة واستدامة الأعمال بدءًا بالاستشارات وصناعة الحلول التقنية، وانتهاءً بالإسناد والتشغيل."
                : "We work to provide solutions that meet the needs of our customers, through an elite group of national minds, by providing a number of innovative electronic services and integrated technical solutions to support the journey of transformation in the performance of the public and private sectors, and to contribute to raising the level of services and ensuring efficiency and business sustainability, starting with consulting and manufacturing technical solutions, and ending with Assignment and operation."}
            </SCompTextImageRightText>
            <Link to={`/${locale}/about-us`}>
              <SCompTextImageRightLink>{isArabic ? "تعرّف أكثر" : "Learn more"}</SCompTextImageRightLink>
            </Link>
          </SCompTextImageRightContent>
        </SCompTextImageRightWrapper>
      </SCompTextImageRightContainer>
    </SCompTextImageRight>
  );
};

export default CompTextImageRight;
