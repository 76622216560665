import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "constants/mediaquerys";

const Small = css`
  color: #000;
  font-size: 18px;
  font-weight: 300;
  line-height: 31px;
  opacity: 0.8;
  margin-top: 27px;
  width: auto;
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SCompTextImageRightText = styled.p`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
