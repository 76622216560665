import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  color: #000;
  font-size: 20px;
  font-weight: 300;
  line-height: 150%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  border-bottom: 1px solid rgba(16, 22, 45, 0.24);
  padding-bottom: 24px;
  
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 90%;
  }
  
  & > svg {
    width: 24px;
    height: 24px;
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SJobApplicationFormFileInputText = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
