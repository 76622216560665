import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "constants/mediaquerys";

const Small = css`
  display: flex;
  align-items: center;
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: 160%;
  transition: color 300ms;
  padding: 12px 0;

  &:hover {
    color: #fd5d63;
  }
`;

const Medium = css``;

const Large = css`
  padding: 0 24px;
`;

const XLarge = css``;

const XXLarge = css``;

export const SHeaderLink = styled.span`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
