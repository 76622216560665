import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "constants/mediaquerys";

const Small = css`
  padding: 80px 24px;
`;

const Medium = css``;

const Large = css`
  padding: ${({ isArabic }) => (isArabic ? "0px 0 25px 70px" : "0px 70px 25px 0")};
`;

const XLarge = css``;

const XXLarge = css``;

export const SCompTextImageRightContainer = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
