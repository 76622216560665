import {useEffect, useRef} from "react";
import {SSuccessModalMessage} from "./styles/SSuccessModalMessage";
import {SSuccessModalMessageContent} from "./styles/SSuccessModalMessageContent";
import {SSuccessModalMessageText} from "./styles/SSuccessModalMessageText";
import {SSuccessModalMessageTitle} from "./styles/SSuccessModalMessageTitle";

const SuccessModalMessage = ({isOpen, setIsOpen, icon, title, text}) => {
  const ref = useRef(null);
  
  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (e.target === ref.current) {
        setIsOpen(false);
      }
    })
  }, []);
  
  useEffect(() => {
    
    let closeModalTimeOut;
    if (isOpen) {
      closeModalTimeOut = setTimeout(() => {
        setIsOpen(false);
      }, 5000);
    }
    
    return () => {
      clearTimeout(closeModalTimeOut);
    }
  }, [isOpen]);
  
  return (
      <SSuccessModalMessage ref={ref} isOpen={isOpen}>
        <SSuccessModalMessageContent>
          {icon}
          <SSuccessModalMessageTitle>
            {title}
          </SSuccessModalMessageTitle>
          <SSuccessModalMessageText>
            {text}
          </SSuccessModalMessageText>
        </SSuccessModalMessageContent>
      </SSuccessModalMessage>
  )
}

export default SuccessModalMessage;