import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";


const CSSWithoutLine = css`
  padding: 0;
  
  &::after {
    display: none;
  }
`
const CSSCenteredLine = css`
  text-align: center;
  
  &::after {
    left: 50%;
    right: unset;
    transform: translateX(-50%);
  }
`

const Small = css`
  color: #000;
  font-size: 45px;
  font-weight: 700;
  line-height: 106.667%;
  padding-bottom: 16px;
  position: relative;
  
  &::after {
    position: absolute;
    top: 100%;
    ${({isArabic}) => isArabic ? "right: 0" : "left: 0"};
    content: "";
    width: 128px;
    height: 4px;
    flex-shrink: 0;
    border-top: 0.667px solid #E5E7EB;
    background: #5C4C8B;
  }
  
  ${({isCenter}) => isCenter && CSSCenteredLine};
  ${({withoutLine}) => withoutLine && CSSWithoutLine};

`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SSectionTitle = styled.h2`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
