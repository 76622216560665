import {useEffect, useState} from "react";
import {fetchData} from "helpers/functions/fetchData";
import {useLoader} from "providers/LoaderProvider";
import {useLocale} from "providers/LocaleProvider";
import {useParams} from "react-router-dom";
import SectionTitle from "components/SectionTitle";
import ProductCard from "components/ProductCard";
import {SCompRelatedProductsWrapper} from "./styles/SCompRelatedProductsWrapper";
import {SCompRelatedProducts} from "./styles/SCompRelatedProducts";
import {SCompRelatedProductsContainer} from "./styles/SCompRelatedProductsContainer";

const CompRelatedProducts = () => {
  const [data, setData] = useState([]);
  const {openLoader, closeLoader} = useLoader();
  const {locale, isArabic} = useLocale();
  const {id} = useParams();
  
  const fetchDataAsync = async () => {
    openLoader();
    try {
      const response = await fetchData('/products/', locale, `&per_page=3&exclude=${id}`);
      setData(response);
      closeLoader();
    } catch {
      closeLoader();
    }
  }
  
  useEffect(() => {
    fetchDataAsync();
  }, [locale]);
  
  
  return (
      <SCompRelatedProducts className="company-product-details">
        <SCompRelatedProductsContainer isArabic={isArabic}>
          <SectionTitle withoutLine>
            {isArabic ? "منتجات ذات صلة" : "Related Products"}
          </SectionTitle>
          <SCompRelatedProductsWrapper>
            {data?.map(card => (
                <ProductCard data={card} key={card.id}/>
            ))}
          </SCompRelatedProductsWrapper>
        </SCompRelatedProductsContainer>
      </SCompRelatedProducts>
  );
};

export default CompRelatedProducts;
