import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";
import {SLearnMoreBtn} from "components/LearnMoreBtn/styles/SLearnMoreBtn";
import {SNewsCardText} from "./SNewsCardText";
import {SNewsCardTitle} from "./SNewsCardTitle";

const Small = css`
  background: #F5F5F5;
  padding: 29px 30px 24px;
  display: flex;
  flex-direction: column;
  
  a:last-of-type {
    margin-top: auto;
  }
  
  transition-property: background, color;
  transition-duration: 300ms;
  
  &:hover {
    background: #FD5D63;
    
    ${SNewsCardTitle}, ${SNewsCardText}, ${SLearnMoreBtn} {
      color: #FAFFF9;
    }
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SNewsCard = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
