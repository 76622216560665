import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  padding: 0 24px 50px;
`;

const Medium = css``;

const Large = css`
  padding: ${({isArabic}) => isArabic ? "0 137px 65px 114px" : "0 114px 65px 137px"};
`;

const XLarge = css``;

const XXLarge = css``;

export const SCompJobDetailsContainer = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
