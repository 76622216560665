import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";


const Small = css`
  background: #ffffff;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
  color: #6B7280;
  font-size: 20px;
  font-weight: 300;
  line-height: 150%;
  text-align: center;
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SCompText = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
