import { Routes, Route, Navigate } from "react-router-dom";

//components
import { GStyleBase } from "constants/global-style";
import Footer from "components/Footer";
import Header from "components/Header";
import Loader from "components/Loader";

//hooks
import { useLocale } from "providers/LocaleProvider";
import { useLoader } from "providers/LoaderProvider";

//pages
import Home from "pages/home";
import Products from "pages/products";
import Careers from "pages/careers";
import News from "pages/news";
import AboutUs from "pages/about-us";
import PageNotFound from "pages/page-not-found";
import ContactUs from "pages/contact-us";
import NewsDetails from "pages/news-details";
import ProductDetails from "pages/product-details";
import JobDetails from "pages/job-details";
import PrivacyPolicy from "pages/privacy-policy";
import Projects from "components/OurProjects";
import "./index.css";

function App() {
  const { isArabic } = useLocale();
  const { isLoading } = useLoader();

  return (
    <div className={`${isArabic && "arr"}`}>
      <GStyleBase isArabic={isArabic} />
      <Header />
      {isLoading && <Loader />}
      <main>
        <Routes>
          <Route path="/" element={<Navigate to="/ar" />} />
          <Route path="/:locale" element={<Home />} />
          <Route path="/:locale/projects" element={<Projects />} />
          <Route path="/:locale/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/:locale/contact-us" element={<ContactUs />} />
          <Route path="/:locale/about-us" element={<AboutUs />} />
          <Route path="/:locale/products" element={<Products />} />
          <Route path="/:locale/products/:id" element={<ProductDetails />} />
          <Route path="/:locale/careers" element={<Careers />} />
          <Route path="/:locale/careers/:id" element={<JobDetails />} />
          <Route path="/:locale/news" element={<News />} />
          <Route path="/:locale/news/:id" element={<NewsDetails />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
