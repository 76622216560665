import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useLocale} from "providers/LocaleProvider";
import CompPageHero from "components/CompPageHero";
import CompContactUs from "components/CompContactUs";

const ContactUs = () => {
  const {setLocale, isArabic} = useLocale();
  const {locale} = useParams();
  
  useEffect(() => {
    setLocale(locale)
  }, [locale, setLocale]);
  
  return (
      <>
        <CompPageHero pageTitle={isArabic ? "تواصل معنا" : "Contact Us"}/>
        <CompContactUs/>
      </>
  );
};

export default ContactUs;
