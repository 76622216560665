import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  flex: 1 1 auto;
  display: ${({isMenuOpened}) => isMenuOpened ? "flex" : "none"};
  position: fixed;
  background: #fff;
  left: 0;
  width: 100vw;
  top: 77px;
  height: calc(100vh - 78px);
  z-index: 20;
  padding: 32px;
  flex-direction: column;
  overflow-y: auto;
`;

const Medium = css``;

const Large = css`
  position: unset;
  height: unset;
  width: unset;
  padding: 0;
  display: flex;
  overflow: unset;
  justify-content: space-between;
  flex-direction: row;
  z-index: unset;
`;

const XLarge = css``;

const XXLarge = css``;

export const SHeaderLinksAndBtns = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
