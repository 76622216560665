import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useLocale} from "providers/LocaleProvider";
import CompPageHero from "components/CompPageHero";
import CompText from "components/CompText";
import CompOurPartners from "components/CompOurPartners";
import CompTextImageLeft from "components/CompTextImageLeft";
import CompWhyUs from "components/CompWhyUs";

const AboutUs = () => {
  const {setLocale, isArabic} = useLocale();
  const {locale} = useParams();
  
  useEffect(() => {
    setLocale(locale)
  }, [locale, setLocale]);
  
  return (
      <>
        <CompPageHero pageTitle={isArabic ? "من نحن" : "About us"}/>
        <CompText>
          {isArabic ?
              "شغفنا و إلتزامنا تجاه الشركات لتمكينهم من إستخدام قوة التكنولوجيا و تحقيق الازدهار في العصر الرقمي من خلال تقديم حلول تكنولوجيا المعلومات المبتكرة وتوجيهات خبرائنا التي تساعدهم علي الظهور بشكل إستثنائي في المشهد الرقمي المتقدم."
              : "Our passion and commitment towards companies is to enable them to use the power of technology and achieve prosperity in the digital age by providing innovative IT solutions and the guidance of our experts that help them emerge exceptionally in the advanced digital landscape."}
        </CompText>
        <CompTextImageLeft/>
        <CompWhyUs/>
        <CompOurPartners/>
      </>
  );
};

export default AboutUs;
