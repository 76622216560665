import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "constants/mediaquerys";

const Small = css`
  padding: 50px 24px;
`;

const Medium = css``;

const Large = css`
  padding: ${({ isArabic }) => (isArabic ? "50px 137px 65px 114px" : "50px 114px 65px 137px")};
`;

const XLarge = css``;

const XXLarge = css``;

export const SCompProductDetailsContainer = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
