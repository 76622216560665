import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";
import {SButton} from "components/Button/styles/SButton";

const Small = css`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 40px;
  
  & > div {
    overflow: hidden;
  }
  
  ${SButton} {
    max-width: 180px;
    min-width: fit-content;
  }
  
  input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid rgba(16, 22, 45, 0.24);
    outline: none;
    padding-bottom: 24px;
    
    &, &::placeholder {
      font-family: Frutiger LT Arabic;
      color: #000;
      font-size: 20px;
      font-weight: 300;
      line-height: 150%;
    }
  }
  
  .error {
    color: red;
    font-size: 14px;
    font-weight: 300;
    line-height: 150%;
    margin-top: 8px;
  }
`;

const Medium = css`
  grid-template-columns: repeat(2, 1fr);
`;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SJobApplicationFormWrapper = styled.form`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
