import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  ${({isArabic}) => isArabic ? "padding: 47px 24px 66px 42px" : "padding: 47px 42px 66px 24px"};
  background: #F5F5F5;
  display: flex;
  flex-direction: column;
  
  img {
    width: 54px;
    height: 54px;
    object-fit: contain;
    margin-bottom: 24px;
  }
`;

const Medium = css``;

const Large = css``;

const XLarge = css``;

const XXLarge = css``;

export const SCompProductDetailsFeature = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
