import {useEffect, useState} from "react";
import {fetchData} from "helpers/functions/fetchData";
import {useLoader} from "providers/LoaderProvider";
import SectionTitle from "components/SectionTitle";
import {useLocale} from "providers/LocaleProvider";
import NewsCard from "components/NewsCard";
import {SCompCompanyNews} from "./styles/SCompCompanyNews";
import {SCompCompanyNewsContainer} from "./styles/SCompCompanyNewsContainer";
import {SCompCompanyNewsWrapper} from "./styles/SCompCompanyNewsWrapper";

const CompCompanyNews = () => {
  const [data, setData] = useState([]);
  const {openLoader, closeLoader} = useLoader();
  const {isArabic, locale} = useLocale();
  
  const fetchDataAsync = async () => {
    openLoader();
    try {
      const response = await fetchData('/posts', locale);
      setData(response);
      closeLoader();
    } catch {
      closeLoader();
    }
  }
  
  useEffect(() => {
    fetchDataAsync();
  }, [locale]);
  
  return (
      <SCompCompanyNews className="company-services">
        <SCompCompanyNewsContainer>
          <SectionTitle isCenter>
            {isArabic ? "أخر الأخبار" : "Latest News"}
          </SectionTitle>
          <SCompCompanyNewsWrapper>
            {data?.map(card => (
                <NewsCard data={card} key={card.id}/>
            ))}
          </SCompCompanyNewsWrapper>
        </SCompCompanyNewsContainer>
      </SCompCompanyNews>
  );
};

export default CompCompanyNews;
