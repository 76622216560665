import styled, { css } from "styled-components";
import { MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE } from "constants/mediaquerys";

const Small = css`
  background: #ffffff;
  position: relative;
  margin: 124px auto 0;
  overflow: hidden;

  .swiper-slide {
    height: fit-content;
  }

  .swiper-container {
    height: 100%;
  }

  .swiper-wrapper {
    align-items: center;
    height: 100%;
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
  }
`;

const Medium = css``;

const Large = css`
  margin: 124px auto;
`;

const XLarge = css``;

const XXLarge = css``;

export const SCompOurPartners = styled.div`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
