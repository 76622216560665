import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {formatDate} from "helpers/functions/formatDate";
import {fetchData} from "helpers/functions/fetchData";
import {useLoader} from "providers/LoaderProvider";
import {SCompNewsSidebar} from "./styles/SCompNewsSidebar";
import {SCompNewsSidebarCard} from "./styles/SCompNewsSidebarCard";
import {SCompNewsSidebarCardContent} from "./styles/SCompNewsSidebarCardContent";
import {SCompNewsSidebarCardDate} from "./styles/SCompNewsSidebarCardDate";
import {SCompNewsSidebarCardImgCont} from "./styles/SCompNewsSidebarCardImgCont";
import {SCompNewsSidebarCards} from "./styles/SCompNewsSidebarCards";
import {SCompNewsSidebarCardTitle} from "./styles/SCompNewsSidebarCardTitle";
import {SCompNewsSidebarMark} from "./styles/SCompNewsSidebarMark";
import {SCompNewsSidebarMarks} from "./styles/SCompNewsSidebarMarks";
import {SCompNewsSidebarWrapper} from "./styles/SCompNewsSidebarWrapper";
import {SCompNewsSidebarWrapperTitle} from "./styles/SCompNewsSidebarWrapperTitle";

const CompNewsSidebar = ({news, locale, isArabic}) => {
  const [categories, setCategories] = useState([]);
  const {openLoader, closeLoader} = useLoader();
  
  const fetchDataAsync = async () => {
    openLoader();
    try {
      const response = await fetchData('/categories', locale, "&hide_empty=true");
      setCategories(response);
      closeLoader();
    } catch {
      closeLoader();
    }
  }
  
  useEffect(() => {
    fetchDataAsync();
  }, [locale]);
  
  return (
      <SCompNewsSidebar className="news-sidebar">
        <SCompNewsSidebarWrapper>
          <SCompNewsSidebarWrapperTitle>
            {isArabic ? "أحدث الأخبار" : "Latest News"}
          </SCompNewsSidebarWrapperTitle>
          <SCompNewsSidebarCards>
            {news.map(el => (
                <SCompNewsSidebarCard key={el.id}>
                  <SCompNewsSidebarCardImgCont isArabic={isArabic}>
                    {el?._embedded?.['wp:featuredmedia']?.[0]?.source_url && (
                        <img src={el._embedded['wp:featuredmedia'][0].source_url} alt=""/>
                    )}
                  </SCompNewsSidebarCardImgCont>
                  <SCompNewsSidebarCardContent>
                    <Link to={`/${locale}/news/${el.id}`}>
                      <SCompNewsSidebarCardTitle>
                        {el.title.rendered}
                      </SCompNewsSidebarCardTitle>
                    </Link>
                    <SCompNewsSidebarCardDate>
                      {formatDate(el.date)}
                    </SCompNewsSidebarCardDate>
                  </SCompNewsSidebarCardContent>
                </SCompNewsSidebarCard>
            ))}
          </SCompNewsSidebarCards>
        </SCompNewsSidebarWrapper>
        <SCompNewsSidebarWrapper>
          <SCompNewsSidebarWrapperTitle>
            {isArabic ? "العلامات" : "Categories"}
          </SCompNewsSidebarWrapperTitle>
          <SCompNewsSidebarMarks>
            {categories?.map(category => (
                <SCompNewsSidebarMark key={category.id}>
                  {category.name}
                </SCompNewsSidebarMark>
            ))}
          </SCompNewsSidebarMarks>
        </SCompNewsSidebarWrapper>
      </SCompNewsSidebar>
  )
}


export default CompNewsSidebar;