import {useLocale} from "providers/LocaleProvider";
import {Link} from "react-router-dom";
import {SCompPageHero} from "./styles/SCompPageHero";
import {SCompPageHeroBreadcrumb} from "./styles/SCompPageHeroBreadcrumb";
import {SCompPageHeroBreadcrumbLink} from "./styles/SCompPageHeroBreadcrumbLink";
import {SCompPageHeroContainer} from "./styles/SCompPageHeroContainer";
import {SCompPageHeroTitle} from "./styles/SCompPageHeroTitle";
import {SCompPageHeroWrapper} from "./styles/SCompPageHeroWrapper";

const CompPageHero = ({pageTitle}) => {
  const {isArabic, locale} = useLocale();
  
  return (
      <SCompPageHero className="page-hero">
        <SCompPageHeroContainer>
          <SCompPageHeroWrapper>
            <SCompPageHeroTitle>
              {pageTitle}
            </SCompPageHeroTitle>
            <SCompPageHeroBreadcrumb isArabic={isArabic}>
              <Link to={`/${locale}`}>
                <SCompPageHeroBreadcrumbLink>
                  {isArabic ? "الرئيسية" : "Home"}
                </SCompPageHeroBreadcrumbLink>
              </Link>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_223_581)">
                  <path fillRule="evenodd" clipRule="evenodd" d="M7.88684 10.5892C7.56142 10.2638 7.56142 9.73612 7.88684 9.4107L10.2439 7.05368C10.4823 6.81534 10.8407 6.74404 11.152 6.87303C11.4634 7.00201 11.6665 7.30588 11.6665 7.64293L11.6665 12.357C11.6665 12.694 11.4634 12.9979 11.152 13.1269C10.8407 13.2559 10.4823 13.1845 10.2439 12.9462L7.88684 10.5892Z" fill="#09244B"/>
                </g>
                <defs>
                  <clipPath id="clip0_223_581">
                    <rect width="20" height="20" fill="white" transform="translate(20) rotate(90)"/>
                  </clipPath>
                </defs>
              </svg>
              <SCompPageHeroBreadcrumbLink>
                {pageTitle}
              </SCompPageHeroBreadcrumbLink>
            </SCompPageHeroBreadcrumb>
          </SCompPageHeroWrapper>
        </SCompPageHeroContainer>
      </SCompPageHero>
  );
};

export default CompPageHero;
