import SectionTitle from "components/SectionTitle";
import {useLocale} from "providers/LocaleProvider";
import {SCompTextImageLeft} from "./styles/SCompTextImageLeft";
import {SCompTextImageLeftContainer} from "./styles/SCompTextImageLeftContainer";
import {SCompTextImageLeftContent} from "./styles/SCompTextImageLeftContent";
import {SCompTextImageLeftImg} from "./styles/SCompTextImageLeftImg";
import image from "assets/images/about-us-image.png";
import {SCompTextImageLeftImgWrapper} from "./styles/SCompTextImageLeftImgWrapper";
import {SCompTextImageLeftText} from "./styles/SCompTextImageLeftText";
import {SCompTextImageLeftWrapper} from "./styles/SCompTextImageLeftWrapper";

const CompTextImageLeft = () => {
  const {isArabic} = useLocale();

  return (
      <SCompTextImageLeft className="test-image-left">
        <SCompTextImageLeftContainer isArabic={isArabic}>
          <SCompTextImageLeftWrapper>
            <SCompTextImageLeftContent>
              <SectionTitle withoutLine>
                {isArabic ? "قصتنا" : "Our Story"}
              </SectionTitle>
              <SCompTextImageLeftText>
                {isArabic ?
                    "تأسست Details Digital في عام 2015، وقد تطورت من رؤية للاستفادة من التكنولوجيا في تغيير إيجابي. منذ بداياتنا المتواضعة، نمونا إلى فريق دينامي من الخبراء، ملتزمون بتقديم حلول مبتكرة تدفع نجاح الأعمال."
                    :
                    "Established in 2015, Details Digital has evolved from a vision of leveraging technology for positive change. From our humble beginnings, we have grown into a dynamic team of experts, dedicated to delivering innovative solutions that drive business success."}
              </SCompTextImageLeftText>
            </SCompTextImageLeftContent>
            <SCompTextImageLeftImgWrapper isArabic={isArabic}>
              <SCompTextImageLeftImg>
                <img src={image} alt="developer" loading="lazy"/>
              </SCompTextImageLeftImg>
            </SCompTextImageLeftImgWrapper>
          </SCompTextImageLeftWrapper>
        </SCompTextImageLeftContainer>
      </SCompTextImageLeft>
  );
};

export default CompTextImageLeft;
