import styled, {css} from "styled-components";
import {MIN_LARGE, MIN_MEDIUM, MIN_XLARGE, MIN_XXLARGE} from "constants/mediaquerys";

const Small = css`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  
  input {
    padding: 6px 16px;
    border: 1px solid #DCDEDE;
    background: rgba(255, 255, 255, 0.90);
    outline: 0;
    color: #9CA3AF;
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    height: 48px;
    ${({isArabic}) => isArabic ? "margin-left: 20px" : "margin-right: 20px"};
    margin-bottom: 24px;
    
    &::placeholder {
      color: #9CA3AF;
    }
  }
`;

const Medium = css`
  flex-direction: row;
  align-items: center;
  
  input {
    width: 400px;
    ${({isArabic}) => isArabic ? "margin-left: 60px" : "margin-right: 60px"};
    margin-bottom: 0;
  }

`;

const Large = css`
`;

const XLarge = css``;

const XXLarge = css``;

export const SCompNewsletterWrapper = styled.form`
  ${Small};
  ${MIN_MEDIUM`${Medium}`};
  ${MIN_LARGE`${Large}`};
  ${MIN_XLARGE`${XLarge}`};
  ${MIN_XXLARGE`${XXLarge}`};
`;
