import {useEffect, useState} from "react";
import {fetchData} from "helpers/functions/fetchData";
import {useLoader} from "providers/LoaderProvider";
import {useLocale} from "providers/LocaleProvider";
import {useParams} from "react-router-dom";
import NewsCard from "components/NewsCard";
import SectionTitle from "components/SectionTitle";
import {SCompRelatedNewsWrapper} from "./styles/SCompRelatedNewsWrapper";
import {SCompRelatedNews} from "./styles/SCompRelatedNews";
import {SCompRelatedNewsContainer} from "./styles/SCompRelatedNewsContainer";

const CompRelatedNews = () => {
  const [data, setData] = useState([]);
  const {openLoader, closeLoader} = useLoader();
  const {locale, isArabic} = useLocale();
  const {id} = useParams();
  
  const fetchDataAsync = async () => {
    openLoader();
    try {
      const response = await fetchData('/posts/', locale, `&per_page=3&exclude=${id}`);
      setData(response);
      closeLoader();
    } catch {
      closeLoader();
    }
  }
  
  useEffect(() => {
    fetchDataAsync();
  }, [locale]);
  
  
  return (
      <SCompRelatedNews className="company-news-details">
        <SCompRelatedNewsContainer>
          <SectionTitle withoutLine>
            {isArabic ? "أخبار ذات صلة" : "Related News"}
          </SectionTitle>
          <SCompRelatedNewsWrapper>
            {data?.map(card => (
                <NewsCard data={card} key={card.id}/>
            ))}
          </SCompRelatedNewsWrapper>
        </SCompRelatedNewsContainer>
      </SCompRelatedNews>
  );
};

export default CompRelatedNews;
